import axios from 'axios';
import {serverUrl} from "../../constants";


export const getPlanFact = async (wbacc_ids, is_like, is_hide, period) => {
    try {
        const response = await axios.get(serverUrl + "plan-fact/get/", {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                'wbacc_ids': wbacc_ids,
                'is_like': is_like,
                'is_hide': is_hide,
                'st': period[0].format('YYYY-MM-DD'),
                'en': period[1].format('YYYY-MM-DD'),
            }
        });
        return response.data;
    } catch (err) {
        if (err.response && err.response.status === 401) {
            console.log(err.response.data.error);
        } else {
            console.error('An unexpected error happened:', err);
        }
        return []; // или null, в зависимости от того, что вы хотите вернуть при ошибке
    }
};


export const updateLikeHide = (article, typ, isCsrf) => {

    try {
        const data = {
            'article': article,
            'typ': typ,
        }
        const response = axios.post(serverUrl + "plan-fact/update/like-hide/", data,
                {
                    withCredentials: true,
                    headers:
                        {
                            "Content-Type":
                                "application/json",
                            "X-CSRFToken":
                            isCsrf,
                        }
                    ,

                }
            )
        ;
        return response.data;
    } catch (err) {
        if (err.response && err.response.status === 401) {
            console.log(err.response.data.error);
        } else {
            console.error('An unexpected error happened:', err);
        }
        return []; // или null, в зависимости от того, что вы хотите вернуть при ошибке
    }
};