import axios from 'axios';
import {serverUrl} from "../../constants";


export const getSalesReport = async (wbacc_ids, month) => {
    try {
        const response = await axios.get(serverUrl + "sales-report/get/", {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                'wbacc_ids': wbacc_ids,
                'month': month,
            }
        });
        return response.data;
    } catch (err) {
        if (err.response && err.response.status === 401) {
            console.log(err.response.data.error);
        } else {
            console.error('An unexpected error happened:', err);
        }
        return []; // или null, в зависимости от того, что вы хотите вернуть при ошибке
    }
};
