import {useParams} from "react-router-dom";
import dayjs from "dayjs";
import React, {useEffect, useState} from "react";
import {getAdvert} from "../../core/api/marketing/adverts";
import {Card, Col, Row, Typography} from "antd";
import DatePickerRange from "../../components/simple/dateRange";
import {SelectorSolo} from "../../components/simple/selects";
import TableAdverts from "../../components/smart/tables/marketing/adverts";
import TableAdvert from "../../components/smart/tables/marketing/advert";
import {formatCurrency, formatNumber, formatPercent, formatQuantity} from "../../core/utils/formats";
import {CardDiff} from "../../components/simple/cards/cardDiff";
import {MarketingChart} from "../../components/simple/charts/marketingChart";
import {AdvertCard} from "../../components/simple/cards/advertCard";
import {useAuth} from "../../core/hooks/useAuth";

export default function Advert() {
    let {id, wbacc_id} = useParams();
    const {isCsrf} = useAuth();
    console.log(id);
    const startDate = dayjs().date() == 1 ? dayjs().subtract(1, 'month').startOf('month') : dayjs().startOf('month');
    const [period, setPeriod] = useState([startDate, dayjs()]);
    const [loading, setLoading] = useState(true);
    const [advert, setAdvert] = useState();
    const [dataChart, setDataChart] = useState([])
    const [miniblocks, setMiniblocks] = useState([]);
    const itemsChart = {
        budget: 'Расходы',
        views: 'Показы',
        clicks: 'Клики',
        add_to_cart: 'Корзина',
        orders: 'Заказы',
        orders_sum: 'Сумма заказов',
        cpc: 'CPC',
        ctr: 'CTR',
        cpo: 'CPO',
        drr: 'ДРР',
        romi: 'ROMI',
        associated_add_to_cart: 'Ассоциативные корзины',
        associated_orders: 'Ассоциативные заказы',
        associated_orders_sum: 'Ассоциативные сумма заказов',

    }

    const fetchData = async () => {
        try {
            setLoading(true);
            const fetchedData = await getAdvert(
                id,
                wbacc_id,
                period
            );
            setAdvert(fetchedData['advert']);
            setLoading(false);
            let data_ = []
            fetchedData?.advert?.stat.forEach((stat) => {
                for (let key in itemsChart) {
                    data_.push({date: stat.date, value: stat[key], category: itemsChart[key]})
                }
            })
            data_.sort((a, b) => (new Date(a.date) - new Date(b.date)));
            setDataChart(data_);
            const stat = fetchedData['sum_stat']
            setMiniblocks([
                [
                    {title: 'Расходы', value: formatCurrency(stat.budget), diff: formatCurrency(stat.diff_budget), is_up: stat.diff_budget > 0, is_green: stat.diff_budget < 0},
                    {title: 'ROMI', value: stat.romi, diff: stat.diff_romi, is_up: stat.diff_romi > 0, is_green: stat.diff_romi > 0},
                    {title: 'ДРР', value: formatPercent(stat.drr), diff: formatPercent(stat.diff_drr), is_up: stat.diff_drr > 0, is_green: stat.diff_drr < 0},
                ],
                [
                    {title: 'Показы', value: formatNumber(stat.views), diff: formatNumber(stat.diff_views), is_up: stat.diff_views > 0, is_green: stat.diff_views > 0},
                    // {title: 'Рекламных кампаний', value: stat.quantity_adverts, diff: stat.diff_quantity_adverts, is_up: stat.diff_quantity_adverts > 0, is_green: stat.diff_quantity_adverts > 0},
                    // {title: 'Товаров в рекламе', value: stat.quantity_nm_ids, diff: stat.diff_quantity_nm_ids, is_up: stat.diff_quantity_nm_ids > 0, is_green: stat.diff_quantity_nm_ids > 0},
                ],
                [
                    {title: 'Клики', value: formatNumber(stat.clicks), diff: formatNumber(stat.diff_clicks), is_up: stat.diff_clicks > 0, is_green: stat.diff_clicks > 0},
                    {title: 'CPC', value: formatCurrency(stat.cpc), diff: formatCurrency(stat.diff_cpc), is_up: stat.diff_cpc > 0, is_green: stat.diff_cpc < 0},
                    {title: 'CTR', value: formatPercent(stat.ctr), diff: formatPercent(stat.diff_ctr), is_up: stat.diff_ctr > 0, is_green: stat.diff_ctr > 0},
                ],
                [
                    {title: 'Корзина', value: formatNumber(stat.add_to_cart), diff: formatNumber(stat.diff_add_to_cart), is_up: stat.diff_add_to_cart > 0, is_green: stat.diff_add_to_cart > 0},
                    {title: 'Стоимость корзины', value: formatCurrency(stat.add_to_cart_cost), diff: formatCurrency(stat.diff_add_to_cart_cost), is_up: stat.diff_add_to_cart_cost > 0, is_green: stat.diff_add_to_cart_cost < 0},
                    {title: 'Добавление в корзину', value: formatPercent(stat.add_to_cart_percent), diff: formatPercent(stat.diff_add_to_cart_percent), is_up: stat.diff_add_to_cart_percent > 0, is_green: stat.diff_add_to_cart_percent > 0},
                ],
                [
                    {title: 'Заказы', value: formatQuantity(stat.orders), diff: formatQuantity(stat.diff_orders), is_up: stat.diff_orders > 0, is_green: stat.diff_orders > 0},
                    {title: 'Стоимость заказа', value: formatCurrency(stat.cpo), diff: formatCurrency(stat.diff_cpo), is_up: stat.diff_cpo > 0, is_green: stat.diff_cpo < 0},
                    {title: 'Выручка', value: formatCurrency(stat.orders_sum), diff: formatCurrency(stat.diff_orders_sum), is_up: stat.diff_orders_sum > 0, is_green: stat.diff_orders_sum > 0},
                ],
                [
                    {title: 'Асс. Корзина', value: formatNumber(stat.associated_add_to_cart), diff: formatNumber(stat.diff_associated_add_to_cart), is_up: stat.diff_associated_add_to_cart > 0, is_green: stat.diff_associated_add_to_cart > 0},
                    {title: 'Асс. Заказы', value: formatQuantity(stat.associated_orders), diff: formatQuantity(stat.diff_associated_orders), is_up: stat.diff_associated_orders > 0, is_green: stat.diff_associated_orders > 0},
                    {title: 'Асс. Сумма заказов', value: formatCurrency(stat.associated_orders_sum), diff: formatCurrency(stat.diff_associated_orders_sum), is_up: stat.diff_associated_orders_sum > 0, is_green: stat.diff_associated_orders_sum > 0},
                ]
            ])
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        fetchData();
    }, [period]);
    return (
        <>
            <Row type='flex'>
                <Col lg={6} xs={24} >
                    <AdvertCard advert={advert} isCsrf={isCsrf} period={period} setPeriod={setPeriod} />
                </Col>
                <Col lg={18} xs={24}>
                    <Card >
                        <MarketingChart dataChart={dataChart}/>
                    </Card>
                </Col>
            </Row>

            <Row>
                {miniblocks.map((item) => {
                    return <Col xs={24} sm={12} md={7} lg={4}>
                        <CardDiff items={item}/>
                    </Col>
                })}

            </Row>


            <Card>
                <TableAdvert
                    period={period}
                    advert={advert?.stat}
                    loading={loading}
                />
            </Card>

        </>
    );
}