import React, { useEffect, useState } from 'react';
import { useAuth } from '../../core/hooks/useAuth';
import { getSalesReport } from '../../core/api/finances/sales_report';
import dayjs from 'dayjs';
import { Card, Col, Flex, Progress, Row, Space, Typography } from 'antd';

import DatePickerRange from '../../components/simple/dateRange';
import { SelectorMulti, SelectorSolo } from '../../components/simple/selects';
import TablePlanFact from '../../components/smart/tables/analCards/planFact';
import TableSalesReport from '../../components/smart/tables/finances/salesReport';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

const { Title } = Typography;
const getColor = (value) => {
    const colors = [
        { value: 90, color: '#31ce36' },
        { value: 70, color: '#ffad46' },
        { value: 50, color: '#f25961' },
    ];
    colors.forEach((item) => {
        if (item.value < value) {
            return item.color;
        }
    });
};

const ProgressCard = (item) => {
    return (
        <Card>
            <Title level={5} style={{ margin: '0px' }}>
                {item.name}
            </Title>

            <Flex justify='space-between'>
                <span>% план заказано</span>
                <div>
                    <span>{item.orders_percent}%</span>
                    <span
                        style={{
                            fontSize: '10px',
                            color: item.orders_prev_color,
                        }}
                    >
                        {item.orders_prev_dif}%<UpOutlined />
                    </span>
                </div>
            </Flex>

            <Progress
                percent={item.orders_percent}
                style={{ height: '0px' }}
                strokeColor={item.orders_color}
                showInfo={false}
            />

            <Flex justify='space-between'>
                <span>% план выкуп</span>
                <div>
                    <span>{item.buyouts_percent}%</span>
                    <span
                        style={{
                            fontSize: '10px',
                            color: item.buyouts_prev_color,
                        }}
                    >
                        {item.buyouts_prev_dif}%<DownOutlined />
                    </span>
                </div>
            </Flex>
            <Progress
                percent={item.buyouts_percent}
                style={{ height: '0px' }}
                strokeColor={item.buyouts_color}
                showInfo={false}
            />
        </Card>
    );
};

function SalesReport() {
    const { user } = useAuth();
    console.log('user', user);
    const [data, setData] = useState();
    const [miniBlocks, setMiniBlocks] = useState([]);
    const [selectedWbaccs, setSelectedWbaccs] = useState([]);
    const [optionsWbaccs, setOptionsWbaccs] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(dayjs().month() + 1);
    const [optionsMonth, setOptionsMonth] = useState([]);
    useEffect(() => {
        setSelectedWbaccs(user.wbacc_ids);
    }, [user.wbacc_ids]);
    const fetchData = async () => {
        const data = await getSalesReport(selectedWbaccs, selectedMonth);
        console.log('data', data);
        setData(data['data']);
        setOptionsWbaccs(data['filter']?.wbaccs);
        setOptionsMonth(data['filter']?.months);
        setMiniBlocks(data['miniblocks']);
    };

    useEffect(() => {
        if (user.wbacc_ids) {
            fetchData();
        }
    }, [selectedWbaccs, selectedMonth]);
    console.log('selectedWbaccs', selectedWbaccs);

    return (
        <>
            <Card>
                <Row type='flex' style={{ gap: 10 }}>
                    <Col lg={5} xs={24} style={{ margin: '7px' }}>
                        <Title level={5} style={{ margin: '0px' }}>
                            Поставщики
                        </Title>
                        <SelectorMulti
                            options={optionsWbaccs}
                            selected={selectedWbaccs}
                            setSelected={setSelectedWbaccs}
                        />
                    </Col>

                    <Col lg={3} xs={24} style={{ margin: '7px' }}>
                        <Title level={5} style={{ margin: '0px' }}>
                            Месяц
                        </Title>
                        <SelectorSolo
                            options={optionsMonth}
                            selected={selectedMonth}
                            setSelected={setSelectedMonth}
                        />
                    </Col>
                </Row>
            </Card>

            <Row>
                {miniBlocks?.map((item) => {
                    return (
                        <Col lg={6} md={12} xs={24}>
                            {ProgressCard(item)}
                        </Col>
                    );
                })}
            </Row>

            <Card>
                <TableSalesReport data={data} />
            </Card>
        </>
    );
}

export default SalesReport;
