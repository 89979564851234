import axios from 'axios';
import { serverUrl } from '../../constants';

export const getPositions = async(wbacc_id, is_like, is_hide, period) => {
    try {
        const response = await axios.get(serverUrl + 'positions/get/', {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },

            params: {
                wbacc_ids: [wbacc_id],
                is_like: is_like,
                is_hide: is_hide,
                st: period[0].format('YYYY-MM-DD'),
                en: period[1].format('YYYY-MM-DD'),
            },
        });
        console.log('Response from getPositions:', response.data);
        return response.data;
    } catch (err) {
        console.error('Error in getPositions:', err); // Логируем ошибку
        if (err.response) {
            console.error('Server responded with:', err.response.data);
            console.error('Status code:', err.response.status);
        } else if (err.request) {
            console.error('No response received:', err.request);
        } else {
            console.error('Error setting up request:', err.message);
        }
        return []; // или null, в зависимости от того, что вы хотите вернуть при ошибке
    }
};