import axios from 'axios';
import {serverUrl} from "../../constants";


export const getEditCards = async (wbacc_id, is_like, is_hide) => {
    try {
        const response = await axios.get(serverUrl + "edit-cards/get/", {withCredentials: true, headers: {"Content-Type": "application/json",}, params: {'wbacc_ids': [wbacc_id], 'is_like': is_like, 'is_hide': is_hide,}});
        return response.data;
    } catch (err) {
        if (err.response && err.response.status === 401) {
            console.log(err.response.data.error);
        } else {
            console.error('An unexpected error happened:', err);
        }
        return [];
    }
};


export const updateEditCards = (card, isCsrf) => {
    try {
        const data = {
            'card': card,
        }
        const response = axios.post(serverUrl + "edit-cards/update/", data, {withCredentials: true, headers: {"Content-Type": "application/json", "X-CSRFToken": isCsrf,},});
        return response.data;
    } catch (err) {
        if (err.response && err.response.status === 401) {
            console.log(err.response.data.error);
        } else {
            console.error('An unexpected error happened:', err);
        }
        return [];
    }
};

export const updatePositions = (nm_id, positions, isCsrf) => {

    try {
        const data = {
            'nm_id': nm_id,
            'positions': positions,
        }
        const response = axios.post(serverUrl + "edit-cards/update/positions/", data, {withCredentials: true, headers: {"Content-Type": "application/json", "X-CSRFToken": isCsrf,},});
        return response.data;
    } catch (err) {
        if (err.response && err.response.status === 401) {
            console.log(err.response.data.error);
        } else {
            console.error('An unexpected error happened:', err);
        }
        return [];
    }
};