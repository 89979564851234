import {Flex, FlexProps, theme, Typography} from 'antd';
import {Link} from 'react-router-dom';
import {CSSProperties} from 'react';

import './styles.css';


export const Logo = ({ asLink, color, href, imgSize, bgColor, ...others }) => {
    const {
        token: {borderRadius},
    } = theme.useToken();

    return asLink ? (
        <Link to={href || '#'} className="logo-link">
            <Flex gap={others.gap || 'small'} align="center" {...others}>
                <img
                    src="assets/images/logo.svg"
                    alt="design sparx logo"
                    height={imgSize?.h || 48}
                />
                <Typography.Title
                    level={5}
                    type="secondary"
                    style={{
                        color,
                        margin: 0,
                        padding: `4px 8px`,
                        backgroundColor: bgColor,
                        borderRadius,
                    }}
                >
                    Antd Admin
                </Typography.Title>
            </Flex>
        </Link>
    ) : (
        <Flex gap={others.gap || 'small'} align="center" {...others}>
            <img
                src="assets/images/logo.svg"
                alt="design sparx logo"
                height={imgSize?.h || 48}
            />

        </Flex>
    );
};
