import {Button, Checkbox, Col, Divider, Flex, Form, Input, message, Row, theme, Typography} from 'antd';
import {FacebookFilled, GoogleOutlined, TwitterOutlined} from '@ant-design/icons';

//import {useMediaQuery} from 'react-responsive';
//import {PATH_AUTH, PATH_DASHBOARD} from '../../constants';
import {useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {Logo} from "../../components/simple/Logo";
import axios from "axios";
import {userInfo} from "../../core/api/user/auth";
import {useAuth} from "../../core/hooks/useAuth";
import {serverUrl} from "../../core/constants";
import {getCSRF} from "../../core/api/user/auth";

const {Title, Text, Link} = Typography;


const isResponseOk = (res) => {
    if (!(res.status >= 200 && res.status <= 299)) {
        throw Error(res.statusText);
    }
}


export const SignInPage = () => {
    const navigate = useNavigate();
    const {isAuth, setIsAuth, isCsrf, setIsCsrf} = useAuth()

    if (isAuth) {
        navigate('/');
    }
    useEffect(() => {
        getCSRF(setIsCsrf);
    }, []);
    const [isLogin, setIsLogin] = useState('')
    const [isPassword, setIsPassword] = useState('')
    const [isError, setIsError] = useState(null)
    const [username, setUsername] = useState('')

    const login = () => {

        const data = {username: isLogin, password: isPassword}
        axios.post(serverUrl + "user/login/", data, {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": isCsrf,
            }
        })
            .then((res) => {
                isResponseOk(res)
                setIsAuth(true)
                setIsLogin('')
                setIsPassword('')
                setIsError(null)
                navigate('/');
                localStorage.setItem('isAuth', true);
            })
            .catch((err) => {
                console.error(err);
                setIsError("Неверные данные")
                message.open({
                    type: 'error',
                    content: 'Неверные данные',
                });
            });
    }

    function changePassword(e) {
        setIsPassword(e.target.value)
    }

    function changeLogin(e) {
        setIsLogin(e.target.value)
    }

    function submitForm(e) {
        e.preventDefault()
        login()
    }

    const {
        token: {colorPrimary},
    } = theme.useToken();
    const isMobile = window.innerWidth <= 769;

    return (
        <Row style={{minHeight: isMobile ? 'auto' : '100vh', overflow: 'hidden'}}>
            <Col xs={24} lg={12}>
                <Flex vertical
                      align="center"
                      justify="center"
                      className="text-center"
                      style={{background: colorPrimary, height: '100%', padding: '1rem'}}>
                    <Logo color="white"/>
                    <Title level={2} className="text-white">
                        Добро пожаловать
                    </Title>
                    <Text className="text-white" style={{fontSize: 18}}>

                    </Text>
                </Flex>
            </Col>
            <Col xs={24} lg={12}>
                <Flex vertical
                      align={isMobile ? 'center' : 'flex-start'}
                      justify="center"
                      gap="middle"
                      style={{height: '100%', padding: '2rem'}}>
                    <Title className="m-0">Войти</Title>
                    <Flex gap={4}>
                        <Text>Нет аккаунта?</Text>
                        <Link href='/signup'>Создать аккаунт</Link>
                    </Flex>
                    <Form name="sign-up-form"
                          layout="vertical"
                          labelCol={{span: 24}}
                          wrapperCol={{span: 24}}
                          initialValues={{
                              remember: true,
                          }}
                          autoComplete="on"
                          requiredMark={false}>
                        <Row gutter={[8, 0]}>
                            <Col xs={24}>
                                <Form.Item label="Имя пользователя"
                                           name="username"
                                           onChange={changeLogin}
                                           rules={[
                                               {required: true, message: 'Please input your username'},
                                           ]}
                                >
                                    <Input autocomplete="username"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Form.Item label="Пароль"
                                           name="password"

                                           onChange={changePassword}

                                           rules={[
                                               {required: true, message: 'Please input your password!'},
                                           ]}
                                >
                                    <Input.Password autocomplete="current-password"/>
                                </Form.Item>
                            </Col>
                            {/*<Col xs={24}>*/}
                            {/*    <Form.Item name="remember" valuePropName="checked">*/}
                            {/*        <Checkbox>Remember me</Checkbox>*/}
                            {/*    </Form.Item>*/}
                            {/*</Col>*/}
                        </Row>
                        <Form.Item>
                            <Flex align="center" justify="space-between">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="middle"
                                    onClick={submitForm}
                                >
                                    Войти
                                </Button>
                                <Link href='/'>Забыли пароль?</Link>
                            </Flex>
                        </Form.Item>
                    </Form>

                </Flex>
            </Col>
        </Row>
    );
};
