import { EyeOutlined, HeartOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { Checkbox, Switch, Table } from 'antd';
import React, { useState } from 'react';


const getDaysBetweenDates = (startDate, endDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const diffDays = Math.round(Math.abs((startDate - endDate) / oneDay));
    return diffDays + 1;
};

const getColumns = (
    filters_data,
    likeHideList,
    onChangeLikeHide,
    isHideColumns,
    period
) => {
    const dateColumns = [];
    const daysBetween = getDaysBetweenDates(
        period[0].toDate(),
        period[1].toDate()
    );

    for (let i = 0; i < daysBetween; i++) {
        const date = new Date(period[1].toDate());
        date.setDate(date.getDate() - i);
        const formattedDate = date.toISOString().split('T')[0];
        dateColumns.push(formattedDate);
    }

    const columns = [
        { title: '', dataIndex: '', width: 20, fixed: 'left' },
        {
            title: <HeartOutlined />, width: 21,
            render: (text, record) => (
                <Checkbox
                    checked={likeHideList['like'].includes(record.nm_id)}
                    onChange={(e) =>
                        onChangeLikeHide(record, e.target.checked, 'like')
                    }
                />
            ),
            hidden: isHideColumns,
        },
        {
            title: <EyeOutlined />, width: 21,
            render: (text, record) => (
                <Checkbox
                    checked={likeHideList['hide'].includes(record.nm_id)}
                    onChange={(e) =>
                        onChangeLikeHide(record, e.target.checked, 'hide')
                    }
                />
            ),
            hidden: isHideColumns,
        },
        {
            title: '', dataIndex: 'img', width: 20, fixed: 'left',
            render: (text) => <img src={text} style={{ height: '20px' }} />,
        },
        {
            title: 'Артикул продавца', dataIndex: 'article', ellipsis: true, width: 100,
            render: (text) => <a href={`product/${text}`}>{text}</a>,
            hidden: isHideColumns,
        },
        {
            title: 'Артикул WB', dataIndex: 'nm_id', width: 70, ellipsis: true, fixed: 'left',
        },
        {
            title: 'Карточка', dataIndex: 'imt_id', width: 80, ellipsis: true, filters: filters_data['imt_ids'],
            filterSearch: true, onFilter: (value, record) => record.imt_id == value || record.is_fix,
        },
        {
            title: 'Категория', dataIndex: 'subject_name', key: 'subject_name', ellipsis: true, width: 100, fixed: 'left', filters: filters_data?.table_subjects, filterSearch: true,
            onFilter: (value, record) =>
                record.subject_id == value || record.is_fix,
        },
        {
            title: 'Модель', dataIndex: 'material', width: 100, ellipsis: true, filters: filters_data?.material, fixed: 'left', filterSearch: true,
            onFilter: (value, record) =>
                record.material?.indexOf(value) === 0 || record.is_fix,
        },
        {
            title: 'Ключевая фраза', dataIndex: 'position', key: 'position', ellipsis: true, width: 150, fixed: 'left',
        },
        {
            title: 'Цвет', dataIndex: 'color', width: 100, ellipsis: true, filters: filters_data?.color, filterSearch: true,
            onFilter: (value, record) => record.color?.indexOf(value) === 0,
        },
        {
            title: 'ABC', dataIndex: 'abc', width: 80, ellipsis: true, filters: filters_data?.abc, filterSearch: true,
            onFilter: (value, record) => record.abc?.indexOf(value) === 0,
        },
        {
            title: 'Сезон', dataIndex: 'sezon', width: 70, ellipsis: true, filters: filters_data?.sezon, filterSearch: true,
            onFilter: (value, record) => record.sezon?.indexOf(value) === 0,
        },
    ];

    dateColumns.forEach((date, index) => {
        columns.push({
            title: date,
            dataIndex: date,
            render: (text, record) => {
                const positionData = record.positions || {};
                const currentPosition = positionData[date] !== undefined ? positionData[date] : '-';

                if (index < dateColumns.length - 1) {
                    const nextDate = dateColumns[index + 1];
                    const nextPosition = positionData[nextDate] !== undefined ? positionData[nextDate] : null;

                    if (nextPosition !== null) {
                        const difference = currentPosition - nextPosition;

                        // Условие для проверки, если разница равна -1
                        if (difference !== 0) {
                            const color = difference >= 0 ? 'red' : 'green';
                            const icon = difference >= 0 ? <ArrowUpOutlined style={{ color }} /> : <ArrowDownOutlined style={{ color }} />;
                            return (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <span>{currentPosition}</span>
                                    <span style={{ color }}>{icon}{Math.abs(difference)}</span>
                                </div>
                            );
                        }
                    }
                }
                return <span>{currentPosition}</span>;
            },
            width: 100,
        });
    });
    return columns;
};

//! АЛЬТЕРНАТИВНАЯ ВЕРСИЯ (СЧИТАТАЕТ ДАЖЕ ГДЕ ЕСТЬ -1)
//! АЛЬТЕРНАТИВНАЯ ВЕРСИЯ (СЧИТАТАЕТ ДАЖЕ ГДЕ ЕСТЬ -1)
{/*
    dateColumns.forEach((date, index) => {
        columns.push({
            title: date,
            dataIndex: date,
            render: (text, record) => {
                const positionData = record.positions || {};
                const currentPosition = positionData[date] !== undefined ? positionData[date] : '-';

                if (index < dateColumns.length - 1){
                    const nextDate = dateColumns[index + 1];
                    const nextPosition = positionData[nextDate] !== undefined ? positionData[nextDate] : null;

                    if (nextPosition !== null) {
                        const difference = currentPosition - nextPosition;
                        const color = difference >= 0 ? 'red' : 'green';
                        const icon = difference >= 0 ?<ArrowUpOutlined style={{color}} /> : <ArrowDownOutlined style={{color}} />;
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent:'space-between' }}>
                                <span>{currentPosition}</span>
                                <span style={{color}}>{icon}{Math.abs(difference)}</span>
                            </div>
                        );
                    }
                }
                return currentPosition;
            },
            width: 100,
        });
    });
*/}
//! АЛЬТЕРНАТИВНАЯ ВЕРСИЯ (СЧИТАТАЕТ ДАЖЕ ГДЕ ЕСТЬ -1)
//! АЛЬТЕРНАТИВНАЯ ВЕРСИЯ (СЧИТАТАЕТ ДАЖЕ ГДЕ ЕСТЬ -1)

const expandData = (cards) => {
    return cards.map((card) => {
        card['children'] = [];
        let firstPosition = true;

        const uniqueKeyPhrases = {}; // Для уникальных ключевых фраз по артикулу

        for (let position in card.positions) {
            let item = {
                subject_name: card.subject_name,
                subject_id: card.subject_id,
                material: card.material,
                color: card.color,
                abc: card.abc,
                sezon: card.sezon,
                positions: card.positions[position],
                position: position,
            };

            // Сохраняем уникальные ключевые фразы по артикулу
            if (!uniqueKeyPhrases[card.article]) {
                uniqueKeyPhrases[card.article] = position;
            }

            if (firstPosition) {
                firstPosition = false;
            } else {
                card.children.push(item);
            }
        }

        return {
            ...card,
            position: uniqueKeyPhrases[card.article],
            positions: card.positions[Object.keys(card.positions)[0]],
        };
    });
};

export default function TablePosition({
    filters,
    likeHideList,
    onChangeLikeHide,
    loading,
    cards,
    period,
}) {
    const [isHideColumns, setHideColumns] = useState(false);

    let columns = getColumns(
        filters,
        likeHideList,
        onChangeLikeHide,
        isHideColumns,
        period
    );

    let DataSource = cards;
    if (cards) {
        DataSource = expandData(cards);
    }

    return (
        <>
            <Switch
                checkedChildren='Скрыть'
                unCheckedChildren='Скрыть'
                checked={isHideColumns}
                onChange={() => setHideColumns(!isHideColumns)}
            />
            <Table
                columns={columns}
                dataSource={DataSource}
                bordered
                size={'small'}
                scroll={{
                    y: 800,
                }}
                rowClassName={(record, index) => `my-custom-row`}
                loading={loading}
                pagination={{
                    defaultPageSize: 30,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} из ${total} артикулов`,
                }}
            />
        </>
    );
}
