import axios from "axios";
import { useAuth } from "../../hooks/useAuth";
import { serverUrl } from "../../constants";


const isResponseOk = (res) => {
    if (!(res.status >= 200 && res.status <= 299)) {
        throw Error(res.statusText);
    }
}
export const getCSRF = (setIsCsrf) => {
    axios.get(serverUrl + 'user/csrf/', { withCredentials: true })
        .then((res) => {
            isResponseOk(res)
            const csrfToken = res.headers.get('X-CSRFToken')
            setIsCsrf(csrfToken)
        })
        .catch((err) => console.error(err))
}


export const getUser = async() => {
    try {
        const response = await axios.get(serverUrl + "user/get/", {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response.data

    } catch (err) {
        if (err.response && err.response.status === 401) {
            console.log(err.response.data.error);
        } else {
            console.error('An unexpected error happened:', err);
        }
        return []; // или null, в зависимости от того, что вы хотите вернуть при ошибке
    }
};

export const getUserInfo = async() => {
    const response = await axios.get(serverUrl + "user/user-info/", {
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        },
    })
    return response.data;

}