import React, {useEffect, useState} from 'react';
import {Card, Col, Divider, Row, Typography} from 'antd';
import {SelectorMulti, SelectorSolo} from '../../components/simple/selects';
import {getPlanFact, updateLikeHide} from '../../core/api/analCards/plan_fact';
import TablePlanFact from '../../components/smart/tables/analCards/planFact';
import {useAuth} from '../../core/hooks/useAuth';
import DatePickerRange from '../../components/simple/dateRange';
import dayjs from 'dayjs';
import {getCSRF, getUser} from '../../core/api/user/auth';
import {Navigate, useLocation, useNavigate} from 'react-router-dom';
import {options_hide, options_like} from '../../core/constants';
import {defaultOnChangeLikeHide, setLikeHideOnLoadData} from "./utils";

function PlanFact() {
    const {user, isCsrf, setIsCsrf} = useAuth();
    const [filters_data, setFiltersData] = useState([]);
    const [cards, setCards] = useState([]);
    const [sum_stat, setSumStat] = useState([]);
    const [mini_blocks, setMiniBlocks] = useState([]);
    const [selectedWbaccs, setSelectedWbaccs] = useState([]);
    const [showedDays, setShowedDays] = useState([
        dayjs().add(-1, 'd').format('DD.MM'),
    ]);
    const [selected_hide, setSelectedHide] = useState(1);
    const [selected_like, setSelectedLike] = useState(1);
    const startDate =
        dayjs().date() == 1
            ? dayjs().subtract(1, 'month').startOf('month')
            : dayjs().startOf('month');
    const [period, setPeriod] = useState([startDate, dayjs().add(-1, 'd')]);
    const [likeHideList, setLikeHideList] = useState({like: [], hide: []});
    const [loading, setLoading] = useState(true);
    const [isOzon, setIsOzon] = useState(true);
    const onChangeLikeHide = (record, checked, typ) => {
        defaultOnChangeLikeHide(record, checked, typ, likeHideList, setLikeHideList, isCsrf)
    }
    const fetchData = async () => {
        try {
            setLoading(true);
            const fetchedData = await getPlanFact(
                selectedWbaccs,
                selected_like,
                selected_hide,
                period
            );
            //console.log('fetchedData', fetchedData);
            setFiltersData(fetchedData['filters']);
            setCards(fetchedData['cards']);
            setSumStat(fetchedData['sum_stat']);
            setMiniBlocks(fetchedData['mini_blocks']);
            setLikeHideOnLoadData(fetchedData, setLikeHideList)
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    // useEffect(() => {
    //     getCSRF(setIsCsrf);
    //     fetchUser(setSelectedWbaccs);
    //
    // }, []);

    useEffect(() => {
        setIsOzon(true)
        fetchData();
        console.log('selectedWbaccs', selectedWbaccs)
        selectedWbaccs.forEach((item) => {
            if (item > 0) {
                setIsOzon(false);
            }
        })
    }, [selected_hide, selected_like, selectedWbaccs, period]); // Оставляем пустой массив зависимостей для выполнения только один раз при монтировании
    // useEffect(() => {
    //     setSelectedWbaccs(user.wbacc_ids ? user.wbacc_ids[0] : []);
    // }, [user.wbacc_ids]);
    return (
        <>
            <Card>
                <Row type='flex' style={{gap: 10}}>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>
                            Период данных
                        </Typography.Title>
                        <DatePickerRange
                            selected={period}
                            setSelected={setPeriod}
                        />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>
                            Поставщики
                        </Typography.Title>
                        <SelectorMulti
                            options={filters_data.wbaccs}
                            selected={selectedWbaccs}
                            setSelected={setSelectedWbaccs}
                        />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>

                            Избранное
                        </Typography.Title>
                        <SelectorSolo
                            options={options_like}
                            selected={selected_like}
                            setSelected={setSelectedLike}
                        />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>
                            Скрытые товары
                        </Typography.Title>
                        <SelectorSolo
                            options={options_hide}
                            selected={selected_hide}
                            setSelected={setSelectedHide}
                        />
                    </Col>
                </Row>
            </Card>
            <Card>
                <TablePlanFact
                    cards={cards}
                    filters={filters_data}
                    showedDays={showedDays}
                    setShowedDays={setShowedDays}
                    likeHideList={likeHideList}
                    onChangeLikeHide={onChangeLikeHide}
                    loading={loading}
                    sum_stat={sum_stat}
                    isOzon={isOzon}
                />
            </Card>
        </>
    );
}

export default PlanFact;
