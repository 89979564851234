import React from 'react';
import './sub.css'
const plans = [
    {
        title: 'Basic',
        description: 'Plan description',
        price: 9,
        features: [
            'Arcu vitae elementum',
            'Dui faucibus in ornare',
            'Morbi tincidunt augue',
        ],
    },
    {
        title: 'Premium',
        description: 'Plan description',
        price: 29,
        features: [
            'Arcu vitae elementum',
            'Dui faucibus in ornare',
            'Morbi tincidunt augue',
            'Duis ultricies lacus sed',
        ],
    },
    {
        title: 'Enterprise',
        description: 'Plan description',
        price: 49,
        features: [
            'Arcu vitae elementum',
            'Dui faucibus in ornare',
            'Morbi tincidunt augue',
            'Duis ultricies lacus sed',
            'Imperdiet proin',
            'Nisi scelerisque',
        ],
    },
];

export const Subscribe = () => {
    return (
        <div className="surface-0">
            <h1 className="title">Pricing Plans</h1>
            <p className="subtitle">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Velit numquam eligendi quos.</p>
            <div className="grid">
                {plans.map((plan, index) => (
                    <div className="column" key={index}>
                        <div className="card">
                            <h2 className="plan-title">{plan.title}</h2>
                            <p className="plan-description">{plan.description}</p>
                            <hr className="divider" style={{border:'1px solid #b8b8b8'}} />
                            <div className="price-container">
                                <span className="price">${plan.price}</span>
                                <span className="price-label">per month</span>
                            </div>
                            <hr className="divider" style={{border:'1px solid #b8b8b8'}} />
                            <ul className="features">
                                {plan.features.map((feature, featureIndex) => (
                                    <li key={featureIndex}>
                                        <i className="check-icon"></i> {feature}
                                    </li>
                                ))}
                            </ul>
                            <hr className="divider" style={{border:'1px solid #b8b8b8'}} />
                            <button className="buy-button">Buy Now</button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

