import React, {useEffect, useState} from 'react';
import {Card, Col, Divider, message, Row, Typography} from 'antd';
import {SelectorMulti, SelectorSolo} from '../../components/simple/selects';
import {getPlanFact, updateLikeHide} from '../../core/api/analCards/plan_fact';
import TablePlanFact from '../../components/smart/tables/analCards/planFact';
import {useAuth} from '../../core/hooks/useAuth';
import DatePickerRange from '../../components/simple/dateRange';
import dayjs from 'dayjs';
import {getCSRF, getUser} from '../../core/api/user/auth';
import {Navigate, useLocation, useNavigate} from 'react-router-dom';
import {
    apiLoadPrices,
    apiUpdatePrices,
    getPrices,
} from '../../core/api/analCards/prices';
import TablePrices from '../../components/smart/tables/analCards/prices';
import axios from 'axios';
import {options_hide, options_like, serverUrl} from '../../core/constants';
import {defaultOnChangeLikeHide, setLikeHideOnLoadData} from "./utils";

function Prices() {
    const {user, isCsrf, setIsCsrf} = useAuth();
    const [filters_data, setFiltersData] = useState([]);
    const [cards, setCards] = useState([]);
    const [selectedWbaccs, setSelectedWbaccs] = useState([]);
    const [selected_hide, setSelectedHide] = useState(1);
    const [selected_like, setSelectedLike] = useState(1);
    const [likeHideList, setLikeHideList] = useState({like: [], hide: []});
    const [isOzon, setIsOzon] = useState(true);

    const [loading, setLoading] = useState(true);
    const onChangeLikeHide = (record, checked, typ) => {
        defaultOnChangeLikeHide(record, checked, typ, likeHideList, setLikeHideList, isCsrf)
    }

    const fetchData = async () => {
        try {
            setLoading(true);
            const fetchedData = await getPrices(
                selectedWbaccs,
                selected_like,
                selected_hide
            );
            console.log('fetchedData', fetchedData);
            setFiltersData(fetchedData['filters']);
            setCards(fetchedData['cards']);

            setLikeHideOnLoadData(fetchedData, setLikeHideList)
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const loadPrices = async () => {
        try {
            const data = {
                cards: cards.map((card) => {
                    return {
                        nm_id: card.nm_id,
                        price: card.load_price,
                        discount: card.load_discount,
                    };
                }),
                wbacc_id: cards[0].wbacc_id,
            };

            const response = await axios.post(serverUrl + 'prices/load/', data, {withCredentials: true, headers: {'Content-Type': 'application/json', 'X-CSRFToken': isCsrf,},});
            message.open({
                type: 'success',
                content: 'Цены загружены!',
                style: {
                    marginTop: '5vh',
                },
            });
            return response.data;
        } catch (err) {
            console.log('err', err);
            message.open({
                type: 'error',
                content: 'Не удалось загрузить цены',
                style: {
                    marginTop: '5vh',
                },
            });
            return [];
        }
    };
    const updatePrices = () => {
        setLoading(true);
        const data = {wbacc_id: cards[0].wbacc_id};
        const response = axios
            .post(serverUrl + 'prices/update/', data, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': isCsrf,
                },
            })
            .then((res) => {
                fetchData();
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    console.log(err.response.data.error);
                } else {
                    console.error('An unexpected error happened:', err);
                }
            });
        return response.data;
    };

    // useEffect(() => {
    //     getCSRF(setIsCsrf);
    //     fetchUser(setSelectedWbaccs);
    //
    // }, []);
    useEffect(() => {
        setSelectedWbaccs(user.wbacc_ids ? user.wbacc_ids[0] : []);
    }, [user.wbacc_ids]);
    useEffect(() => {
        if (user.wbacc_ids) {
            fetchData();
            setIsOzon(true)
            console.log('selectedWbaccs', selectedWbaccs)
            if (selectedWbaccs > 0) {
                setIsOzon(false);
            }

        }
    }, [selected_hide, selected_like, selectedWbaccs]); // Оставляем пустой массив зависимостей для выполнения только один раз при монтировании

    return (
        <>
            <Card>
                <Row type='flex' style={{gap: 10}}>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>
                            Поставщики{' '}
                        </Typography.Title>{' '}
                        <SelectorSolo
                            options={filters_data.wbaccs}
                            selected={selectedWbaccs}
                            setSelected={setSelectedWbaccs}
                        />{' '}
                    </Col>{' '}
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>
                            {' '}
                            Избранное{' '}
                        </Typography.Title>{' '}
                        <SelectorSolo
                            options={options_like}
                            selected={selected_like}
                            setSelected={setSelectedLike}
                        />{' '}
                    </Col>{' '}
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>
                            Скрытые товары{' '}
                        </Typography.Title>{' '}
                        <SelectorSolo
                            options={options_hide}
                            selected={selected_hide}
                            setSelected={setSelectedHide}
                        />{' '}
                    </Col>{' '}
                </Row>{' '}
            </Card>{' '}
            <Card>
                <TablePrices
                    cards={cards}
                    setCards={setCards}
                    filters={filters_data}
                    likeHideList={likeHideList}
                    onChangeLikeHide={onChangeLikeHide}
                    loading={loading}
                    loadPrices={loadPrices}
                    updatePrices={updatePrices}
                    isOzon={isOzon}
                />{' '}
            </Card>{' '}
        </>
    );
}

export default Prices;
