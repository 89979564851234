import React from "react";
import {Button, Col, Flex, Row, Select, Typography} from "antd";
import {CalendarOutlined} from "@ant-design/icons";
import dayjs from "dayjs";


const SelectorMulti = (props) => {
    const { options, selected, setSelected } = props;
    console.log('selected', selected)
    return (

        <Select
            mode="multiple"
            allowClear
            style={{width: '100%'}}
            placeholder="Please select"
            value={selected}
            onChange={(value) => setSelected(value)}
            options={options}
        />

    );
};

const SelectorSolo = (props) => {
    const { options, selected, setSelected } = props;

    return (

        <Select
            allowClear
            style={{width: '100%'}}
            placeholder="Please select"
            value={selected}
            onChange={(value) => setSelected(value)}
            options={options}
        />

    );
};


export {SelectorMulti, SelectorSolo};