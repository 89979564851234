import {updateLikeHide} from "../../core/api/analCards/plan_fact";


export const defaultOnChangeLikeHide = (record, checked, typ, likeHideList, setLikeHideList, isCsrf) => {
    const ids = [...likeHideList[typ]];
    const index = ids.indexOf(record.article);
    if (checked && index === -1) {
        ids.push(record.article);
    } else if (!checked && index !== -1) {
        ids.splice(index, 1);
    }
    setLikeHideList((prevState) => ({...prevState, [typ]: ids}));
    updateLikeHide(record.article, typ, isCsrf);
};


export const setLikeHideOnLoadData = (fetchedData, setLikeHideList) => {
    let like = [];
    let hide = [];
    fetchedData['cards'].forEach((card, index) => {
        if (Boolean(card['is_like'])) {
            like.push(card['article']);
        }
        if (Boolean(card['is_hide'])) {
            hide.push(card['article']);
        }
    });
    setLikeHideList({like: like, hide: hide});
}