import { Line } from '@ant-design/plots';

export const MarketingChart = (args) => {
    const config = {
        data: args.dataChart,
        xField: (d) => new Date(d.date),
        yField: 'value',
        sizeField: 3,
        shapeField: 'trail',

        colorField: 'category',
    };
    return <Line {...config} />;
};
