import {Button, Checkbox, Col, Divider, Flex, Form, Input, message, Row, theme, Typography} from 'antd';
import {FacebookFilled, GoogleOutlined, TwitterOutlined} from '@ant-design/icons';

import {useNavigate} from 'react-router-dom';
import {useState} from 'react';
import {useAuth} from "../../core/hooks/useAuth";
import {Logo} from "../../components/simple/Logo";
import axios from "axios";
import {serverUrl} from "../../core/constants";


const {Title, Text, Link} = Typography;

const isResponseOk = (res) => {
    if (!(res.status >= 200 && res.status <= 299)) {
        throw Error(res.statusText);
    }
}
export const SignUpPage = () => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')
    const [firstname, setFirstName] = useState('')
    const [lastname, setLastName] = useState('')
    const [isError, setIsError] = useState(null)
    const {isAuth, setIsAuth, isCsrf, setIsCsrf} = useAuth()
    const signup = () => {
        const data = {username: username, password: password, email: email, first_name: firstname, last_name: lastname}
        axios.post(serverUrl + "user/signup/", data, {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": isCsrf,
            }
        })
            .then((res) => {
                isResponseOk(res)
                setIsAuth(true)
                localStorage.setItem('isAuth', true);
                //navigate('/login');
            })
            .catch((res) => {
                console.log(res.response.data.error);
                setIsError("Неверные данные")
                const error_list = []
                for (let key in res.response.data.error) {
                    console.log(key);
                    error_list.push(res.response.data.error[key])
                }
                console.log(error_list);
                message.open({
                    type: 'error',
                    content: error_list.map((item) => item),
                });
            });
    }

    function changePassword(e) {
        setPassword(e.target.value)
    }

    function changeUsername(e) {
        setUsername(e.target.value)
    }

    function changeEmail(e) {
        setEmail(e.target.value)
    }

    function changeFirstName(e) {
        setFirstName(e.target.value)
    }

    function changeLastName(e) {
        setLastName(e.target.value)
    }

    function submitForm(e) {
        e.preventDefault()
        signup()
    }

    const {
        token: {colorPrimary},
    } = theme.useToken();
    const isMobile = window.innerWidth <= 769;
    const navigate = useNavigate();

    return (
        <Row style={{minHeight: isMobile ? 'auto' : '100vh', overflow: 'hidden'}}>
            <Col xs={24} lg={12}>
                <Flex
                    vertical
                    align="center"
                    justify="center"
                    className="text-center"
                    style={{background: colorPrimary, height: '100%', padding: '1rem'}}
                >
                    <Logo color="white"/>
                    <Title level={2} className="text-white">
                        Добро пожаловать
                    </Title>
                    <Text className="text-white" style={{fontSize: 18}}>

                    </Text>
                </Flex>
            </Col>
            <Col xs={24} lg={12}>
                <Flex vertical
                      align={isMobile ? 'center' : 'flex-start'}
                      justify="center"
                      gap="middle"
                      style={{height: '100%', padding: '2rem'}}>
                    <Title className="m-0">Создать аккаунт</Title>
                    <Flex gap={4}>
                        <Text>Уже есть аккаунт?</Text>
                        <Link href='/login'>Войти</Link>
                    </Flex>

                    <Form
                        name="sign-up-form"
                        layout="vertical"
                        labelCol={{span: 8}}
                        wrapperCol={{span: 24}}
                        initialValues={{remember: true}}
                        autoComplete="off"
                        requiredMark={false}
                    >
                        <Row gutter={[8, 0]}>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Имя"
                                    name="firstName"
                                    onChange={changeFirstName}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your first name!',
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Фамилия"
                                    name="lastName"
                                    onChange={changeLastName}
                                    rules={[
                                        {required: true, message: 'Please input your last name!'},
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Form.Item
                                    label="Имя пользователя"
                                    name="username"
                                    onChange={changeUsername}
                                    rules={[
                                        {required: true, message: 'Please input your username'},
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Form.Item
                                    label="Почта"
                                    name="email"
                                    onChange={changeEmail}
                                    rules={[
                                        {required: true, message: 'Please input your email'},
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Form.Item
                                    label="Пароль"
                                    name="password"
                                    onChange={changePassword}
                                    rules={[
                                        {required: true, message: 'Please input your password!'},
                                    ]}
                                >
                                    <Input.Password/>
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Form.Item
                                    label="Подтвердите пароль"
                                    name="cPassword"
                                    rules={[
                                        {required: true, message: 'Please ensure passwords match!'},
                                    ]}
                                >
                                    <Input.Password/>
                                </Form.Item>
                            </Col>
                            {/*<Col xs={24}>*/}
                            {/*    <Form.Item name="terms" valuePropName="checked">*/}
                            {/*        <Flex>*/}
                            {/*            <Checkbox>I agree to</Checkbox>*/}
                            {/*            <Link>terms and conditions</Link>*/}
                            {/*        </Flex>*/}
                            {/*    </Form.Item>*/}
                            {/*</Col>*/}
                        </Row>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="middle"
                                onClick={submitForm}
                            >
                                Зарегистрироваться
                            </Button>
                        </Form.Item>
                    </Form>
                </Flex>
            </Col>
        </Row>
    );
};
