import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Card, Col, Row, Typography } from 'antd';
import { SelectorSolo } from '../../components/simple/selects';
import { updateLikeHide } from '../../core/api/analCards/plan_fact';
import { useAuth } from '../../core/hooks/useAuth';
import dayjs from 'dayjs';
import { options_hide, options_like } from '../../core/constants';
import DatePickerRange from '../../components/simple/dateRange';
import TabelAnalDays from '../../components/smart/tables/analCards/analByDays';
import { getAnalByDays } from '../../core/api/analCards/anal_by_days';
import {defaultOnChangeLikeHide, setLikeHideOnLoadData} from "./utils";

export default function AnalByDays() {
    const { user, isCsrf } = useAuth();
    const [filters_data, setFiltersData] = useState([]);
    const [cards, setCards] = useState([]);
    const [storeNames, setStoreNames] = useState();
    const [regionNames, setRegionNames] = useState();
    const startDate =
        dayjs().date() === 1
            ? dayjs().subtract(1, 'month').startOf('month')
            : dayjs().startOf('month');
    const [period, setPeriod] = useState([startDate, dayjs()]);
    const [selectedWbaccs, setSelectedWbaccs] = useState([]);
    const [selected_hide, setSelectedHide] = useState(1);
    const [selected_like, setSelectedLike] = useState(1);
    const [likeHideList, setLikeHideList] = useState({ like: [], hide: [] });
    const [loading, setLoading] = useState(true);

    const onChangeLikeHide = (record, checked, typ) => {
        defaultOnChangeLikeHide(record, checked, typ, likeHideList, setLikeHideList, isCsrf)
    }

    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            const fetchedData = await getAnalByDays(
                selectedWbaccs,
                selected_like,
                selected_hide,
                period
            );
            setFiltersData(fetchedData['filters']);
            setCards(fetchedData['cards']);
            setStoreNames(fetchedData['store_names']);
            setRegionNames(fetchedData['region_names']);
            setLikeHideOnLoadData(fetchedData, setLikeHideList)
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [selectedWbaccs, selected_like, selected_hide, period]);

    useEffect(() => {
        setSelectedWbaccs(user.wbacc_ids ? user.wbacc_ids[0] : []);
    }, [user.wbacc_ids]);

    useEffect(() => {
        if (user.wbacc_ids) {
            fetchData();
        }
    }, [fetchData]);

    const dateColumns = useMemo(() => {
        const today = new Date();
        return Object.keys(cards[0]?.by_days || {})
            .filter((date) => new Date(date) <= today)
            .sort((a, b) => new Date(b) - new Date(a));
    }, [cards]);

    return (
        <>
            <Card>
                <Row type='flex' style={{ gap: 10 }}>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>
                            Период данных
                        </Typography.Title>
                        <DatePickerRange
                            selected={period}
                            setSelected={setPeriod}
                        />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>
                            Поставщики
                        </Typography.Title>
                        <SelectorSolo
                            options={filters_data?.wbaccs}
                            selected={selectedWbaccs}
                            setSelected={setSelectedWbaccs}
                        />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Избранное</Typography.Title>
                        <SelectorSolo
                            options={options_like}
                            selected={selected_like}
                            setSelected={setSelectedLike}
                        />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>
                            Скрытые товары
                        </Typography.Title>
                        <SelectorSolo
                            options={options_hide}
                            selected={selected_hide}
                            setSelected={setSelectedHide}
                        />
                    </Col>
                </Row>
            </Card>
            <Card>
                <TabelAnalDays
                    period={period}
                    cards={cards}
                    filters={filters_data}
                    likeHideList={likeHideList}
                    onChangeLikeHide={onChangeLikeHide}
                    loading={loading}
                    storeNames={storeNames}
                    regionNames={regionNames}
                    dateColumns={dateColumns}
                />
            </Card>
        </>
    );
}
