import {
    Card,
    ConfigProvider,
    Flex,
    Form,
    Input,
    Row,
    Tabs,
    theme,
} from 'antd';
import { useEffect, useState } from 'react';
import {getUserInfo} from '../../core/api/user/auth';
import { useAuth } from '../../core/hooks/useAuth';
import { Button, Select } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { SelectorMulti } from '../../components/simple/selects';
const TAB_NAMES = ['Информация', 'ВБ Аккаунты', 'Менеджеры'];

export const Profile = () => {
    const { user, setUser, isCsrf } = useAuth();
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(TAB_NAMES[0]);
    const [hide, setHide] = useState([]);
    const [wbaccs, setWbaccs] = useState([]);
    const [like, setLike] = useState([]);
    const {
        token: { borderRadius },
    } = theme.useToken();

    const onChange = (key) => {
        setActiveTab(key);
    };

      const [isTokenVisible, setTokenVisible] = useState(false);

    const toggleTokenVisibility = () => {
        setTokenVisible(!isTokenVisible);
    };

     const fetchData = async () => {
        try {
            setLoading(true);
            const fetchedData = await getUserInfo();
            setUser(fetchedData);

            if (fetchedData.params && fetchedData.params.hide_nm_ids) {
                setHide(fetchedData.params.hide_nm_ids);
            }

            if (fetchedData.params && fetchedData.params.like_nm_ids) {
                setLike(fetchedData.params.like_nm_ids);
            }

            if (fetchedData.wbaccs) {
                setWbaccs(fetchedData.wbaccs);
            }
            console.log('fetchedData', fetchedData)
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const renderTabContent = () => {
        switch (activeTab) {
            case 'Информация':
                return (
                    <Card>
                    <Form
                        name='user-profile-details-form'
                        layout='vertical'
                        initialValues={{
                            id: user.id,
                            username: user.username,
                            firstName: 'Test',
                            lastName: 'Test',
                            email: user.email,
                            phone: user.phone,
                        }}
                        autoComplete='on'
                        requiredMark={false}
                    >
                        <Row gutter={[16, 0]}>
                            <Flex gap={10}>
                                    <Form.Item
                                        label='Имя' name='firstName'
                                        rules={[{required: true,message:'Пожалуйста введите ваше Имя!',},]}
                                    >
                                        <Input style={{width:'400px'}}  />
                                    </Form.Item>
                                    <Form.Item
                                        label='Фамилия' name='lastName'
                                        rules={[
                                            {required: true,message:'Пожалуйста введите вашу Фамилию!',},]}
                                    >
                                        <Input style={{width:'400px'}}   />
                                    </Form.Item>
                                    <Form.Item
                                        label='Телефон' name='phone'
                                        rules={[
                                            {required: true,message:'Пожалуйста введите ваш телефон!',},]}
                                    >
                                        <Input style={{width:'400px'}}   />
                                    </Form.Item>
                            </Flex>
                            <Flex gap={10}>
                                <Form.Item
                                    label='Email' name='email'
                                    rules={[{required: true,message: 'Please input your email!',},]}
                                >
                                    <Input style={{width:'400px'}} />
                                </Form.Item>
                                <Form.Item
                                    label='Username' name='username'
                                    rules={[{ required: true,message: 'Please input your username!', },]}
                                >
                                    <Input style={{width:'400px'}} />
                                </Form.Item>
                                <Flex gap={10}>
                                  <Form.Item label='Избранное'>
                                        <SelectorMulti
                                            options={like.map(item => ({ label: item, value: item }))}
                                            selected={like}
                                            setSelected={setLike}
                                        />
                                    </Form.Item>

                                    {/* Использование компонента SelectorMulti для Скрытого */}
                                    <Form.Item label='Скрытое'>
                                        <SelectorMulti
                                            options={hide.map(item => ({ label: item, value: item }))}
                                            selected={hide}
                                            setSelected={setHide}
                                        />
                                    </Form.Item>
                            </Flex>
                            </Flex>
                        </Row>
                    </Form>
                </Card>
                );
            case 'ВБ Аккаунты':
                return (
                    <>
                        <Button style={{marginLeft:'8px'}}>Добавить аккаунт</Button>
                        {wbaccs.map((item) => 
                            <Card   
                                key={item.id}  name='user-profile-wbaccs-form' layout='vertical' autoComplete='on' requiredMark={false} className='ProfileCard'
                            >
                                <Flex gap={20} align='end'>
                                    <Flex vertical>
                                        <span>Поставщик</span>
                                        <div style={{border:'1px solid #d9d9d9', borderRadius:'5px', padding:'2px 50px 2px 5px'}}>{item.name}</div>
                                    </Flex>
                                    <Flex vertical>
                                        <span>Supplier_Id</span>
                                        <div style={{border:'1px solid #d9d9d9', borderRadius:'5px', padding:'2px 50px 2px 5px'}}>{item.x_supplier_id}</div>
                                    </Flex>
                                    <Button danger type='primary'>Удалить аккаунт</Button>
                                </Flex>
                                <Flex vertical>
                                    <span>Токен</span>
                                    <div style={{
                border: '1px solid #d9d9d9',
                borderRadius: '5px',
                padding: '2px 50px 2px 5px',
                fontSize: '12px',
                overflowWrap: 'break-word',
                wordWrap: 'break-word',
                overflow: 'hidden',
                whiteSpace: isTokenVisible ? 'normal' : 'nowrap'
            }}>
                {isTokenVisible ? item.token : '••••••••••••••••••••••••••••••••••••••••••••••••'}

                    <button onClick={toggleTokenVisibility} style={{ marginTop: '5px', background: 'none', border: 'none', cursor: 'pointer', position:'relative', right:'-20px' }}>
                {isTokenVisible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            </button>
            </div>
                                </Flex>
                            </Card>
                        )}
                    </>
                );
            default:
                return null;
        }
    };
    return (
        <Card
            loading={loading}
            className='user-profile-card-nav card'
        >
            <ConfigProvider
                theme={{
                    components: {
                        Tabs: {
                            colorBorderSecondary: 'none',
                        },
                    },
                }}
            >
                <Tabs
                    defaultActiveKey={TAB_NAMES[0]}
                    activeKey={activeTab}
                    onChange={onChange}
                    style={{ textTransform: 'capitalize' }}
                >
                    {TAB_NAMES.map((name, index) => (
                        <Tabs.TabPane tab={name} key={name}>
                            {renderTabContent()}
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            </ConfigProvider>
        </Card>
    );
};


