// api.js
import axios from 'axios';
import {serverUrl} from '../../constants';
import {message} from "antd";

export const getPrices = async (wbacc_id, is_like, is_hide) => {
    try {
        const response = await axios.get(serverUrl + 'prices/get/', {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                wbacc_ids: [wbacc_id],
                is_like: is_like,
                is_hide: is_hide,
            },
        });
        return response.data;
    } catch (err) {
        if (err.response && err.response.status === 401) {
            console.log(err.response.data.error);
        } else {
            console.error('An unexpected error happened:', err);
        }
        return []; // или null, в зависимости от того, что вы хотите вернуть при ошибке
    }
};


