import Navbar from './containers/navbar';
import './App.css';
import React, {useEffect, useState} from 'react';
import {
    AppstoreOutlined,
    MailOutlined,
    SettingOutlined,
    DownOutlined,
    SmileOutlined,
} from '@ant-design/icons';
import {Breadcrumb, Layout, Menu, theme} from 'antd';
import {Grid} from 'antd';
import axios from 'axios';
import {useAuth} from './core/hooks/useAuth';
import {getCSRF, getSession} from './core/api/user/auth';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import {HomeTest, NoMatchTest} from './pages/test';
import PlanFact from './pages/analCards/planFact';
import SalesReport from './pages/finances/salesReport';
import Prices from './pages/analCards/prices';
import {SignInPage} from './pages/user/SignIn';
import {AuthProvider} from './core/config/Context';
import {SignUpPage} from './pages/user/SignUp';
import {serverUrl} from './core/constants';
import Supplies from './pages/analStock/supplies';
import OrdersYear from './pages/analCards/ordersYear';
import Position from './pages/utils/positions';
import {getPositions} from './core/api/utils/positions';
import dayjs from 'dayjs';
import Anal from './pages/analCards/anal';
import EditCards from './pages/settings/editCards';
import AdvertsReport from './pages/marketing/marketing';
import {getAnalByDays} from './core/api/analCards/anal_by_days';
import {Profile} from './pages/user/profile';
import AnalByDays from './pages/analCards/analByDays';
import Marketing from "./pages/marketing/marketing";
import Adverts from "./pages/marketing/adverts";
import Advert from "./pages/marketing/advert";
import Details from './pages/marketing/marketingDetails';
import Supply from "./pages/analStock/supply";
import {Subscribe} from './pages/subscribe/Subscribe';

export default function App() {
    const {isAuth, setIsAuth, isCsrf, setIsCsrf, user, setUser} = useAuth();
    console.log('start');
    useEffect(() => {
        getCSRF(setIsCsrf);
        axios
            .get(serverUrl + 'user/session/', {withCredentials: true})
            .then((res) => {
                if (res.data.isAuthenticated) {
                    setIsAuth(true);
                    setUser(res.data.user);
                } else {
                    setIsAuth(false);
                }
                console.log('finish');
            })
            .catch((err) => console.error(err));
    }, []);
    // const fetchData = async () => {
    //     const res = await getPositions(1, 1, 1, [
    //         dayjs().add(-14, 'd'),
    //         dayjs(),
    //     ]);
    //     console.log(res);
    // };
    // useEffect(() => {
    //     fetchData();
    // }, []);

    // useEffect(() => {
    //     console.log('start2');
    //     const res = getAnalByDays(1, 1, 1, [dayjs().add(-7, 'd'), dayjs()]);
    //     console.log('getAnalByDays', res);
    // }, []);

    return (
        <Layout>
            {isAuth ? <Navbar/> : <> </>}
            <Layout.Content>
                <Routes>
                    <Route path='/' element={<RequireAuth><HomeTest/></RequireAuth>}/>
                    <Route path='/plan-fact' element={<RequireAuth><PlanFact/></RequireAuth>}/>
                    <Route path='/sales-report' element={<RequireAuth><SalesReport/></RequireAuth>}/>
                    <Route path='/prices' element={<RequireAuth><Prices/></RequireAuth>}/>
                    <Route path='/supplies' element={<RequireAuth><Supplies/></RequireAuth>}/>
                    <Route path='/supply' element={<RequireAuth><Supply/></RequireAuth>}/>
                    <Route path='/orders-year' element={<RequireAuth><OrdersYear/></RequireAuth>}/>
                    <Route path='/login' element={<SignInPage/>}/>
                    <Route path='/signup' element={<SignUpPage/>}/>
                    <Route path='/positions' element={<RequireAuth><Position/></RequireAuth>}></Route>
                    <Route path='/analytic-days' element={<RequireAuth><AnalByDays/></RequireAuth>}></Route>
                    <Route path='/analytic' element={<RequireAuth><Anal/></RequireAuth>}></Route>
                    <Route path='/edit-cards' element={<RequireAuth><EditCards/></RequireAuth>}></Route>
                    <Route path='/marketing' element={<RequireAuth><Marketing/></RequireAuth>}></Route>
                    <Route path="/profile" element={<RequireAuth><Profile/></RequireAuth>}></Route>
                    <Route path="/marketing-details" element={<RequireAuth><Details/></RequireAuth>}></Route>
                    <Route path='/adverts' element={<RequireAuth><Adverts/></RequireAuth>}></Route>
                    <Route path="/advert/:wbacc_id/:id" element={<RequireAuth><Advert/></RequireAuth>}></Route>
                    <Route path="/subscribe" element={<RequireAuth><Subscribe/></RequireAuth>}></Route>
                    <Route path='*' element={<NoMatchTest/>}/>
                </Routes>
            </Layout.Content>
        </Layout>
    );
}

function RequireAuth({children}) {
    const {isAuth} = useAuth();
    let location = useLocation();
    console.log('isAuth', isAuth, 'location', location);
    if (!isAuth) {
        return <Navigate to='/login' state={{from: location}} replace/>;
    }

    return children;
}

//
// import style from './index.module.scss';
//
// // Важно заметить, что если написать http://127.0.0.1:8000 cookie не будут устанавливаться
// const serverUrl = 'http://localhost:8000/'
//
// export default function App() {
//     const [isCsrf, setIsCsrf] = useState(null)
//     const [isLogin, setIsLogin] = useState('')
//     const [isPassword, setIsPassword] = useState('')
//     const [isError, setIsError] = useState(null)
//     const [isAuth, setIsAuth] = useState(false)
//     const [username, setUsername] = useState('')
//     const [userId, setUserId] = useState(null)
//
//     // При первой загрузке страницы мы спрашиваем, авторизован ли пользователь
//     useEffect(() => {
//         getSession()
//     }, [])
//
//     const isResponseOk = (res) => {
//       if (!(res.status >= 200 && res.status <= 299)) {
//         throw Error(res.statusText);
//       }
//     }
//
//     // Если необходимо авторизоваться, запрашиваем CSRF-токен у сервера
//     const getCSRF = () => {
//         axios.get(serverUrl + 'api/csrf/', { withCredentials: true })
//         .then((res) => {
//             isResponseOk(res)
//
//             const csrfToken = res.headers.get('X-CSRFToken')
//             setIsCsrf(csrfToken)
//         })
//         .catch((err) => console.error(err))
//     }
//
//     // withCredentials:true - аналогия директивы credentials='include'
//     const getSession = () => {
//       axios.get(serverUrl + "api/session/", { withCredentials: true })
//       .then((res) => {
//           if (res.data.isAuthenticated) {
//               setUserId(res.data.user_id)
//               setUsername(res.data.username)
//               setIsAuth(true)
//               return
//           }
//
//           setIsAuth(false)
//           getCSRF()
//       })
//       .catch(err => console.error(err))
//     }
//
//     // Полученный CSRF-токен пихаем в заголовок и отправляем серверу
//     const login = () => {
//       const data = { username: isLogin, password: isPassword }
//       axios.post(serverUrl + "api/login/", data, {
//         withCredentials: true,
//         headers: {
//           "Content-Type": "application/json",
//           "X-CSRFToken": isCsrf,
//         }
//       })
//       .then((res) => {
//         isResponseOk(res)
//         setIsAuth(true)
//         setIsLogin('')
//         setIsPassword('')
//         setIsError(null)
//
//         userInfo()
//       })
//       .catch((err) => {
//         console.error(err);
//         setIsError("Неверные данные")
//       });
//     }
//
//     const logout = () => {
//       axios.get(serverUrl + "api/logout", { withCredentials: true })
//       .then((res) => {
//         isResponseOk(res)
//         setIsAuth(false);
//         getCSRF();
//       })
//       .catch(err => console.error(err));
//     }
//
//     const userInfo = () => {
//       axios.get(serverUrl + "api/user_info/", {
//         withCredentials: true,
//         headers: {
//           "Content-Type": "application/json",
//         },
//       })
//       .then((res) => {
//         console.log("Вы авторизованы как: " + res.data.username);
//         setUsername(res.data.username)
//       })
//       .catch((err) => {
//           if (err.status === 401) console.log(err.error);
//       });
//     }
//
//     const killAllSessions = () => {
//       axios.get(serverUrl + "api/kill_all_sessions/", {
//         withCredentials: true,
//         headers: {
//           "Content-Type": "application/json",
//         },
//       })
//       .then((res) => {
//         isResponseOk(res)
//         console.log(res.data.detail)
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//     }
//
//     function changePassword(e) {
//         setIsPassword(e.target.value)
//     }
//
//     function changeLogin(e) {
//         setIsLogin(e.target.value)
//     }
//
//     function submitForm(e) {
//         e.preventDefault()
//         login()
//     }
//
//     return(
//       <div className={style.container}>
//           <div className={style.authStatus}>
//             Вы -
//             <span className={style.username}>
//             {
//               isAuth ? ' ' + username   : ' неавторизованы'
//             }
//             </span>
//           </div>
//
//           {
//             !isAuth ?
//               <form className={style.formContainer}>
//                   <label htmlFor="login">Логин</label>
//                   <input
//                       type="text"
//                       name="login"
//                       id="login"
//                       className={style.field}
//                       onChange={changeLogin}
//                       value={isLogin}
//                   />
//
//                   <label htmlFor="password">Пароль</label>
//                   <input
//                       type="password"
//                       name="password"
//                       id="password"
//                       className={style.field}
//                       onChange={changePassword}
//                       value={isPassword}
//                   />
//
//                   {
//                       isError ? <div className={style.error}>{isError}</div> : null
//                   }
//
//                   <input type="submit" value='Войти' onClick={submitForm} className={style.sendBtn} />
//               </form>
//             :
//
//               <div className={style.btnContainer}>
//                   <input type="submit" value='Выйти' onClick={logout} className={style.logoutBtn} />
//                   <input type="submit" value='Убить все сессии' onClick={killAllSessions} className={style.killAllSessionsBtn} />
//               </div>
//
//           }
//       </div>
//     )
// }
