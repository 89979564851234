import {ArrowDownOutlined, ArrowUpOutlined} from "@ant-design/icons";
import {Card, Flex, Space, Typography} from "antd";
import {green, red} from '@ant-design/colors';

const {Text} = Typography


export const CardDiff = (props) => {
    const {items, justify, height, ...others} = props;

    return (
        <Card>
            <Flex vertical>
                {items.map((item) => {
                    return <>
                        <Typography.Text>{item.title}</Typography.Text>
                        <Flex justify="space-between" align="center">
                            <Typography.Title level={2} style={{margin: 0}}>
                                <span>{item.value}{item.char}</span>
                            </Typography.Title>
                            <Space style={{color: item.is_green ? green[6] : red[5]}}>
                                {item.is_up? <ArrowUpOutlined/> : <ArrowDownOutlined/>}
                                <Typography.Text style={{
                                    color: item.is_green ? green[6] : red[5],
                                    fontWeight: 500,
                                }}>
                                    <span>{item.diff}</span>
                                </Typography.Text>
                            </Space>
                        </Flex>
                    </>
                })}


            </Flex>
        </Card>
    );
};
