export function formatQuantity(number) {
    if (number) {
        const formattedNumber = Number(number).toLocaleString('ru-RU');
        return `${formattedNumber} шт`;
    }
    return number

}

export function formatCurrency(number) {
    if (number) {
        const formattedNumber = Number(number).toLocaleString('ru-RU');
        return `${formattedNumber} ₽`;
    }
    return number

}

export function formatPercent(number) {
    if (number) {
        return `${number}%`;
    }
    return number
}

export function formatDays(number) {
    if (number) {
        return `${number} дн`;
    }
    return number
}

export function formatNumber(number) {
    if (number) {
        const formattedNumber = Number(number).toLocaleString('ru-RU');
        return `${formattedNumber}`;
    }
    return number
}

export function formatAdvertType(number) {
    const types = {
        8: 'Автоматическая',
        9: 'Аукцион'
    }
    return types[number]
}

export function formatAdvertStatus(number) {
    const types = {
        4: 'Готова к запуску',
        7: 'Завершена',
        8: 'Отменена',
        9: 'Активна',
        11: 'Пауза',
    }
    return types[number]
}