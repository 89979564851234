import React from "react";
import {Menu, Avatar} from "antd";
import {UserOutlined, CodeOutlined, LogoutOutlined} from "@ant-design/icons";
import axios from "axios";
import {getCSRF} from "../../core/api/user/auth";
import {serverUrl} from "../../core/constants";
import {useAuth} from "../../core/hooks/useAuth";
import {Link, useNavigate} from "react-router-dom";

const RightMenu = ({mode}) => {
    const {isAuth, setIsAuth} = useAuth()
    const navigate = useNavigate();
    const logout = () => {
        console.log('loguout')
        axios.get(serverUrl + "user/logout/", {withCredentials: true})
            .then((res) => {
                setIsAuth(false);
                localStorage.setItem('isAuth', false);
                navigate('/login')
            })
            .catch(err => console.error(err));
    }
    const onClick = (e) => {
        if (e.key == 'log-out') {
            logout();
        }
        console.log('click ', e);
    };
    return (
        <Menu mode={mode} onClick={onClick}>
            <Menu.SubMenu
                title={
                    <>
                        <Avatar icon={<UserOutlined/>}/>
                        <span className="username">John Doe</span>
                    </>
                }
            >
                <Menu.Item key="profile">

                    <UserOutlined/><Link to="/profile">Профиль</Link>
                </Menu.Item>
                <Menu.Item key="log-out">
                    <LogoutOutlined/> Выйти
                </Menu.Item>
            </Menu.SubMenu>
        </Menu>
    );
};

export default RightMenu;