import React, {useState} from "react";
import {Menu} from "antd";
import {BarChartOutlined, CalculatorOutlined, EyeOutlined, InboxOutlined, MailOutlined, ProductOutlined, SettingOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import Prices from "../../pages/analCards/prices";

const items = [
    {
        label: 'Финансы', key: 'finance', icon: <CalculatorOutlined/>,
        children: [
            {label: <Link to="/sales-report">Отчет по продажам</Link>, key: 'finance:1',},

        ]
    },
    {
        label: 'Товарная аналитика', key: 'products_anal', icon: <BarChartOutlined/>,
        children: [
            {label: <Link to="/analytic">Аналитика</Link>, key: 'products_anal:1',},
            {label: <Link to="/analytic-days">Аналитика по дням</Link>, key: 'products_anal:5',},
            {label: <Link to="/plan-fact">План Факт</Link>, key: 'products_anal:2',},
            {label: <Link to="/orders-year">Продажи год</Link>, key: 'products_anal:3',},
            {label: <Link to="/prices">Ценообразование</Link>, key: 'products_anal:4',},


        ],
    },

    {
        label: 'Складская аналитика', key: 'stock_anal', icon: <InboxOutlined/>,
        children: [
            {label: <Link to="/supplies">Поставки</Link>, key: 'stock_anal:1',},
            {label: <Link to="/supply">Создать поставку</Link>, key: 'stock_anal:2',},
        ],
    },
    {
        label: 'Маркетинг', key: 'marketing', icon: <EyeOutlined/>,
        children: [
            {label: <Link to="/marketing">Общий отчет</Link>, key: 'marketing:1',},
            {label: <Link to="/marketing-details">Общий отчет детализация</Link>, key: 'marketing:2',},
            {label: <Link to="/adverts">Рекламные кампании</Link>, key: 'marketing:3',},

        ],
    },
    {
        label: 'Утилиты', key: 'utils', icon: <ProductOutlined/>,
        children: [
            {label: <Link to="/positions">Позиции</Link>, key: 'utils:1',},
        ],
    },
    {
        label: 'Настройки', key: 'settings', icon: <SettingOutlined/>,
        children: [
            {label: <Link to="/edit-cards">Карточки</Link>, key: 'settings:1',},
        ],
    },

];

const LeftMenu = ({mode}) => {
    const [current, setCurrent] = useState('mail');
    const isLoggedIn = Boolean(1);
    const user = {'username': 'asdy10'}
    const onClick = (e) => {
        console.log('click ', e);
        setCurrent(e.key);
    };
    return (
        <div className="menuContainer">
            <Menu mode={mode} onClick={onClick} selectedKeys={[current]} items={items}/>
        </div>

    );
};

export default LeftMenu;