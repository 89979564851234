import {formatAdvertStatus, formatAdvertType, formatCurrency, formatDays, formatNumber, formatPercent, formatQuantity} from "../../../../core/utils/formats";
import {EyeOutlined, HeartOutlined} from "@ant-design/icons";
import {Checkbox, Switch, Table} from "antd";
import React, {useState} from "react";
import {Link} from "react-router-dom";


const getColumns = (isHideColumns) => {

    return [
        //{title: '', dataIndex: 'img', width: 20, fixed: 'left', render: (text) => <img src={text} style={{'height': '20px'}}></img>,},
        {title: 'ID', dataIndex: 'id', ellipsis: true, width: 50, render: (text, record) => <Link to={`/advert/${record.wbacc_id}/${record.id}`}>{text}</Link>,},
        {title: 'Название', dataIndex: 'name', width: 200, ellipsis: true},
        {title: 'Артикулы WB', dataIndex: 'nm_ids', width: 80, ellipsis: true, render: (text, record, index) => `${record?.nm_ids}`,},
        {title: 'Тип', dataIndex: 'advert_type', width: 50, ellipsis: true, render: (text) => formatAdvertType(text),},
        {title: 'Статус', dataIndex: 'status', width: 50, ellipsis: true, render: (text) => formatAdvertStatus(text),},
        {title: 'Расход', dataIndex: 'budget', width: 50, ellipsis: true, render: (text) => formatCurrency(text),},
        {title: 'Показы', dataIndex: 'views', width: 50, ellipsis: true, render: (text) => formatNumber(text),},
        {title: 'Клики', dataIndex: 'clicks', width: 50, ellipsis: true, render: (text) => formatNumber(text),},
        {title: 'Корзина', dataIndex: 'add_to_cart', width: 50, ellipsis: true, render: (text) => formatNumber(text),},
        {title: 'Заказы', dataIndex: 'orders', width: 50, ellipsis: true, render: (text) => formatQuantity(text),},
        {title: 'Сумма заказов', dataIndex: 'orders_sum', width: 50, ellipsis: true, render: (text) => formatCurrency(text),},
        {title: 'CTR', dataIndex: 'ctr', width: 50, ellipsis: true, render: (text) => formatPercent(text),},
        {title: 'CPC', dataIndex: 'cpc', width: 50, ellipsis: true, render: (text) => formatCurrency(text),},
        {title: 'Процент в корзину', dataIndex: 'add_to_cart_percent', width: 50, ellipsis: true, render: (text) => formatPercent(text),},
        {title: 'Стоимость корзины', dataIndex: 'add_to_cart_cost', width: 50, ellipsis: true, render: (text) => formatCurrency(text),},
        {title: 'Стоимость заказа', dataIndex: 'cpo', width: 50, ellipsis: true, render: (text) => formatCurrency(text),},
        {title: 'Ставка', dataIndex: 'cpm', width: 50, ellipsis: true, render: (text) => formatCurrency(text),},
        {title: 'ДРР', dataIndex: 'drr', width: 50, ellipsis: true, render: (text) => formatPercent(text),},
        {title: 'ROMI', dataIndex: 'romi', width: 50, ellipsis: true},
        {title: 'Период', dataIndex: 'period', width: 70, ellipsis: true, render: (text, record, index) => `${record?.period?.st}-${record?.period?.en}`,},

    ]
}


export default function TableAdverts(args) {
    const [isHideColumns, setHideColumns] = useState(false)
    let columns = getColumns(isHideColumns);

    return <>
        {/*<Switch checkedChildren="Скрыть"*/}
        {/*        unCheckedChildren="Скрыть"*/}
        {/*        checked={isHideColumns}*/}
        {/*        onChange={() => setHideColumns(!isHideColumns)}/>*/}
        <Table
            columns={columns}
            dataSource={args.adverts}
            bordered
            size={'small'}
            scroll={{
                y: 800,
            }}
            rowClassName={(record, index) => `my-custom-row`}
            loading={args.loading}
            pagination={{'defaultPageSize': 30, 'showTotal': (total, range) => `${range[0]}-${range[1]} из ${total} кампаний`}}

        />
    </>
}