import React, {useEffect, useState} from "react";
import {Layout, Button, Drawer, Space} from "antd";
import LeftMenu from "./LeftMenu";
import RightMenu from "./RightMenu";
import {MailOutlined, MenuOutlined} from "@ant-design/icons";


function Navbar () {
    const [visible, setVisible] = useState(false);
    const showDrawer = () => {
        setVisible(!visible);
    };

    return (
        <nav className="navbar">
            <div className="nav-header">
                <div className="logo">
                    <img height='40' src='assets/images/logo.svg'/>
                    {/*<h3 className="brand-font">Brand Here</h3>*/}
                </div>

                <div className="navbar-menu">
                    <div className="leftMenu">
                        <LeftMenu mode={"horizontal"}/>
                    </div>
                    <Button className="menuButton" type="text" onClick={showDrawer}>
                        <MenuOutlined/>
                    </Button>
                    <div className="rightMenu">
                        <RightMenu mode={"horizontal"}/>
                    </div>

                    <Drawer
                        title={"Brand Here"}
                        placement="right"
                        closable={true}
                        onClose={showDrawer}
                        visible={visible}
                        style={{zIndex: 99999}}
                    >
                        <LeftMenu mode={"inline"}/>
                        <RightMenu mode={"inline"}/>
                    </Drawer>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;