import axios from 'axios';
import { serverUrl } from '../../constants';
import { message } from "antd";

export const getAdverts = async(wbacc_id, statusType, advertType, period) => {
    try {
        const response = await axios.get(serverUrl + 'adverts/get/', {
            withCredentials: true,
            headers: { 'Content-Type': 'application/json', },
            params: {
                wbacc_ids: [wbacc_id],
                status_type: statusType,
                advert_type: advertType,
                st: period[0].format('YYYY-MM-DD'),
                en: period[1].format('YYYY-MM-DD'),
            },
        });
        return response.data;
    } catch (err) {
        return [];
    }
};


export const getAdvert = async(advertId, wbacc_id, period) => {
    try {
        const response = await axios.get(serverUrl + 'advert/get/', {
            withCredentials: true,
            headers: { 'Content-Type': 'application/json', },
            params: {
                advert_id: advertId,
                wbacc_id: wbacc_id,
                st: period[0].format('YYYY-MM-DD'),
                en: period[1].format('YYYY-MM-DD'),
            },
        });
        return response.data;
    } catch (err) {
        return [];
    }
};


export const advertAddBalance = (wbacc_id, advert_id, value, setBalance, method, isCsrf) => {
    try {
        const data = {
            'wbacc_id': wbacc_id,
            'advert_id': advert_id,
            'value': value,
            'method': method,
        }
        axios.post(serverUrl + "advert/edit/add-balance/", data, { withCredentials: true, headers: { "Content-Type": "application/json", "X-CSRFToken": isCsrf, }, })
            .then((response) => {
                setBalance(response.data.balance);
                response.data.balance != 0 ?
                    message.open({ type: 'success', content: 'Баланс пополнен!', style: { marginTop: '5vh', }, }) :
                    message.open({ type: 'error', content: 'Не удалось пополнить баланс!', style: { marginTop: '5vh', }, });
            })
    } catch (err) {
        message.open({ type: 'error', content: 'Не удалось пополнить баланс!', style: { marginTop: '5vh', }, });
        return [];
    }
};


export const getAdvertBalance = (advert_id, wbacc_id, setBalance) => {
    try {
        axios.get(serverUrl + 'advert/get/balance/', {
            withCredentials: true,
            headers: { 'Content-Type': 'application/json' },
            params: {
                advert_id: advert_id,
                wbacc_id: wbacc_id,
            },
        }).then((response) => {
            console.log('getAdvertBalance', response.data);
            setBalance(response.data.balance);
        });

    } catch (err) {
        return []; // или null, в зависимости от того, что вы хотите вернуть при ошибке
    }
};


export const getAdvertBalanceWBAcc = (wbacc_id, setBalanceWBAcc) => {
    try {
        axios.get(serverUrl + 'adverts/get/balance-wbacc/', {
            withCredentials: true,
            headers: { 'Content-Type': 'application/json' },
            params: {
                wbacc_id: wbacc_id,
            },
        }).then((response) => {
            console.log('getAdvertBalanceWBAcc', response.data);
            setBalanceWBAcc(response.data.data);
        });

    } catch (err) {
        return []; // или null, в зависимости от того, что вы хотите вернуть при ошибке
    }
};

export const advertSetCpm = (wbacc_id, advert_id, advert_type, cpm, param, instrument, isCsrf) => {
    try {
        const data = {
            'wbacc_id': wbacc_id,
            'advert_id': advert_id,
            'advert_type': advert_type,
            'cpm': cpm,
            'param': param,
            'instrument': instrument
        }
        axios.post(serverUrl + "advert/edit/set-cpm/", data, { withCredentials: true, headers: { "Content-Type": "application/json", "X-CSRFToken": isCsrf, }, })
            .then((response) => {
                response.data.success != 0 ?
                    message.open({ type: 'success', content: 'Ставка изменена!', style: { marginTop: '5vh', }, }) :
                    message.open({ type: 'error', content: 'Не удалось изменить ставку!', style: { marginTop: '5vh', }, });
            })
    } catch (err) {
        message.open({ type: 'error', content: 'Не удалось изменить ставку!', style: { marginTop: '5vh', }, });
        return [];
    }
};


export const advertSetActive = (wbacc_id, advert_id, active, setAdvertStatus, isCsrf) => {
    try {
        const data = {
            'wbacc_id': wbacc_id,
            'advert_id': advert_id,
            'active': active,
        }
        axios.post(serverUrl + "advert/edit/set-active/", data, { withCredentials: true, headers: { "Content-Type": "application/json", "X-CSRFToken": isCsrf, }, })
            .then((response) => {
                if (response.data.success) {
                    message.open({ type: 'success', content: 'Статус кампании изменен!', style: { marginTop: '5vh', }, });
                    const statuses = {
                        start: 9,
                        pause: 11,
                        stop: 7,
                    }
                    console.log('new status ', active, statuses[active]);
                    setAdvertStatus(statuses[active]);
                } else {
                    message.open({ type: 'error', content: 'Не удалось изменить статус кампании!', style: { marginTop: '5vh', }, });
                }


            })
    } catch (err) {
        message.open({ type: 'error', content: 'Не удалось изменить статус кампании!', style: { marginTop: '5vh', }, });
        return [];
    }
};