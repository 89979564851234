import {Divider, Switch, Table} from "antd";
import React from "react";
import {formatCurrency, formatDays, formatPercent, formatQuantity} from "../../../../core/utils/formats";

function transformData(data) {
    let table = []
    let arr = {
        budget: {stat: [], name: 'Расходы'},
        views: {stat: [], name: 'Показы'},
        clicks: {stat: [], name: 'Клики'},
        add_to_cart: {stat: [], name: 'Корзина'},
        orders: {stat: [], name: 'Заказы'},
        orders_sum: {stat: [], name: 'Сумма заказов'},
        cpc: {stat: [], name: 'CPC'},
        ctr: {stat: [], name: 'CTR'},
        cpo: {stat: [], name: 'CPO'},
        drr: {stat: [], name: 'ДРР'},
        romi: {stat: [], name: 'ROMI'},
        associated_add_to_cart: {stat: [], name: 'Ассоциативные корзины'},
        associated_orders: {stat: [], name: 'Ассоциативные заказы'},
        associated_orders_sum: {stat: [], name: 'Ассоциативные сумма заказов'},
    }
    data.forEach((stat) => {
        for (let key_stat in arr) {
            arr[key_stat].stat.push({value: stat[key_stat], index: stat.date, cellKey: key_stat})
        }
    })
    for (let key_stat in arr) {
        let new_item = {name: arr[key_stat].name}
        arr[key_stat].stat.forEach((item_stat) => {
            new_item[item_stat.index] = item_stat.value
        })
        table.push(new_item)
    }
    return table
}


function formatCell(text, name) {
    if (['Расходы', 'Сумма заказов', 'CPC', 'CPO'].includes(name)) {
        return formatCurrency(text)
    }
    if (['Заказы',].includes(name)) {
        return formatQuantity(text)
    }
    if (['CTR', 'ДРР'].includes(name)) {
        return formatPercent(text)
    }
    return text
}

export default function TableAdvert(args) {
    let days_columns = []
    let columns = []
    days_columns = args.advert?.map((item) => {
        return {
            title: item.date,
            dataIndex: item.date,
            render: (text, record) => formatCell(text, record.name),
        }
    })
    if (days_columns) {
        columns = [{title: '', dataIndex: 'name', width: 110, ellipsis: true, fixed: 'left'}].concat(days_columns)
    }
    console.log(columns);
    let table = []
    if (args.advert) {
        table = transformData(args.advert)
    }
    console.log(table);

    return <>
        <Table
            columns={columns}
            dataSource={table}
            bordered
            size={'small'}
            rowClassName={(record, index) => `my-custom-row advert_row${index}`}
            loading={args.loading}
            pagination={false}
        />
    </>

}