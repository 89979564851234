import {Link, Outlet} from "react-router-dom";
import React, {useState} from "react";
import {useAuth} from "../../core/hooks/useAuth";

export function LayoutTest() {
    return (
        <div>
            {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}
            <nav>
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/about">About</Link>
                    </li>
                    <li>
                        <Link to="/dashboard">Dashboard</Link>
                    </li>
                    <li>
                        <Link to="/nothing-here">Nothing Here</Link>
                    </li>
                </ul>
            </nav>

            <hr/>

            {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
            <Outlet/>
        </div>
    );
}



export function HomeTest() {
    const {isLoggedIn, setIsLoggedIn} = useAuth()
    return (
        <div>

        </div>
    );
}

export function AboutTest() {
    return (
        <div>
            <h2>About</h2>
        </div>
    );
}

export function DashboardTest() {
    return (
        <div>
            <h2>Dashboard</h2>
        </div>
    );
}

export function NoMatchTest() {
    return (
        <div>
            <h2>Nothing to see here!</h2>
            <p>
                <Link to="/">Go to the home page</Link>
            </p>
        </div>
    );
}