import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Card, Col, Row, Typography } from 'antd';
import { SelectorSolo } from '../../components/simple/selects';
import { updateLikeHide } from '../../core/api/analCards/plan_fact';
import { useAuth } from '../../core/hooks/useAuth';
import dayjs from 'dayjs';
import { options_hide, options_like } from '../../core/constants';
import DatePickerRange from '../../components/simple/dateRange';
import TableDetails from '../../components/smart/tables/marketing/marketingDetails';
import { getDetails } from '../../core/api/marketing/marketing_detail';
import {defaultOnChangeLikeHide, setLikeHideOnLoadData} from "../analCards/utils";

export default function Details() {
    const { user, isCsrf } = useAuth();
    const startDate = dayjs().date() === 1 ? dayjs().subtract(1, 'month').startOf('month') : dayjs().startOf('month');

    const [filters_data, setFiltersData] = useState([]);
    const [period, setPeriod] = useState([startDate, dayjs()]);
    const [selectedWbaccs, setSelectedWbaccs] = useState([]);
    const [selected_hide, setSelectedHide] = useState(1);
    const [selected_like, setSelectedLike] = useState(1);
    const [likeHideList, setLikeHideList] = useState({ like: [], hide: [] });
    const [loading, setLoading] = useState(true);
    const [cards, setCards] = useState([]); 

    const onChangeLikeHide = (record, checked, typ) => {
        defaultOnChangeLikeHide(record, checked, typ, likeHideList, setLikeHideList, isCsrf)
    }

    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            const fetchedData = await getDetails(
                    selectedWbaccs, 
                    selected_like, 
                    selected_hide, 
                    period
                );
            console.log('Fetched Data:', fetchedData); 
            setFiltersData(fetchedData['filters']);
            setLikeHideOnLoadData(fetchedData, setLikeHideList)
            if (fetchedData['cards']) {
                setCards(fetchedData['cards']);
            } else {
                console.warn('No data found in the fetched data');
                setCards([]);
            }

            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    }, [selectedWbaccs, selected_like, selected_hide, period]);


    useEffect(() => {
        setSelectedWbaccs(user.wbacc_ids ? user.wbacc_ids[0] : []);
    }, [user.wbacc_ids]);

    useEffect(() => {
        if (user.wbacc_ids) {
            fetchData();
        }
    }, [fetchData]);

    const dateColumns = useMemo(() => {
        const today = new Date();
        return Object.keys(cards[0]?.by_days || {})
            .filter((date) => new Date(date) <= today)
            .sort((a, b) => new Date(b) - new Date(a));
    }, [cards]);
    
    return (
        <>
            <Card>
                <Row type='flex' style={{ gap: 10 }}>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Период данных</Typography.Title>
                        <DatePickerRange selected={period} setSelected={setPeriod} />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Поставщики</Typography.Title>
                        <SelectorSolo options={filters_data?.wbaccs} selected={selectedWbaccs} setSelected={setSelectedWbaccs} />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Избранное</Typography.Title>
                        <SelectorSolo options={options_like} selected={selected_like} setSelected={setSelectedLike} />
                    </Col>
                    <Col lg={3} xs={24}>
                        <Typography.Title level={5}>Скрытые товары</Typography.Title>
                        <SelectorSolo options={options_hide} selected={selected_hide} setSelected={setSelectedHide} />
                    </Col>
                </Row>
            </Card>
            <Card>
                <TableDetails 
                    cards={cards}
                    filters={filters_data}
                    likeHideList={likeHideList}
                    onChangeLikeHide={onChangeLikeHide}
                    loading={loading}
                    dateColumns={dateColumns} />
            </Card>
        </>
    );
}
