import {
    formatCurrency,
    formatDays,
    formatPercent,
    formatQuantity,
} from '../../../../core/utils/formats';
import {EyeOutlined, HeartOutlined, MinusOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, Checkbox, Flex, Switch, Table} from 'antd';
import React, {useState} from 'react';

const getColumns = (filters_data, likeHideList, onChangeLikeHide, isHideColumns,hiddenColumns, isOzon) => {
    return [
        {title: '', dataIndex: '', width: 20, fixed: 'left'},
        {title: <HeartOutlined/>, width: 21, hidden: isHideColumns, render: (text, record) => (<Checkbox checked={likeHideList['like'].includes(record.article)} onChange={(e) => onChangeLikeHide(record, e.target.checked, 'like')}/>),},
        {title: <EyeOutlined/>, width: 21, hidden: isHideColumns, render: (text, record) => (<Checkbox checked={likeHideList['hide'].includes(record.article)} onChange={(e) => onChangeLikeHide(record, e.target.checked, 'hide')}/>),},
        {title: '', dataIndex: 'img', width: 20, fixed: 'left', render: (text) => <img src={text} style={{height: '20px'}}></img>,},
        {title: 'Артикул продавца', dataIndex: 'article', ellipsis: true, width: 100, fixed: 'left', render: (text) => <a href={`product/${text}`}>{text}</a>, hidden: isHideColumns,},
        {title: 'Артикул WB', dataIndex: 'nm_id', width: 70, ellipsis: true, fixed: 'left', hidden: isOzon,},
        {title: 'Карточка', dataIndex: 'imt_id', width: 80, ellipsis: true, filters: filters_data['imt_ids'], hidden: isOzon, filterSearch: true, onFilter: (value, record) => record.imt_id == value || record.is_fix,},
        {title: 'Категория', dataIndex: 'subject_name', key: 'subject_name', ellipsis: true, width: 100, fixed: 'left', filters: filters_data?.table_subjects, filterSearch: true, onFilter: (value, record) => record.subject_id == value || record.is_fix,},
        {title: 'Модель', dataIndex: 'material', width: 100, ellipsis: true, filters: filters_data?.material, fixed: 'left', filterSearch: true, onFilter: (value, record) => record.material?.indexOf(value) === 0 || record.is_fix,},
        {title: 'Цвет', dataIndex: 'color', width: 100, ellipsis: true, filters: filters_data?.color, filterSearch: true, onFilter: (value, record) => record.color?.indexOf(value) === 0,},
        {title: 'Размер', dataIndex: 'size', width: 70, ellipsis: true},
        {title: 'Баркод', dataIndex: 'barcode', ellipsis: true, width: 70},
        {title: 'Рейтинг', dataIndex: 'rating', width: 50},
        {title: 'Деньги в товаре', dataIndex: 'ost_sum', width: 90, sorter: (a, b) => a.ost_sum - b.ost_sum, render: (text) => formatCurrency(text), className: 'blue-color',},
        {title: 'ABC', dataIndex: 'abc', width: 50, ellipsis: true, filters: filters_data?.abc, filterSearch: true, onFilter: (value, record) => record.abc?.indexOf(value) === 0,},
        {title: 'Сезон', dataIndex: 'sezon', width: 60, ellipsis: true, filters: filters_data?.sezon, filterSearch: true, onFilter: (value, record) => record.sezon?.indexOf(value) === 0,},
        {title: 'Статус', dataIndex: 'status', width: 70, ellipsis: true, filters: filters_data?.status, filterSearch: true, onFilter: (value, record) => record.status?.indexOf(value) === 0,},
        {title: 'Логистика', dataIndex: 'delivery_cost_unit', width: 70, className: 'blue-color', render: (text) => formatCurrency(text),},
        {title: 'Выкуп', dataIndex: 'buyout_percent', width: 70, fixed: 'left', className: 'blue-color', render: (text) => formatPercent(text),},
        {
            title: 'Цена',
            className: 'green-color',
            children: [
                {title: 'Цена', dataIndex: 'price_client', width: 70, className: 'green-color', render: (text) => formatCurrency(text),},
                {title: 'Маржа', dataIndex: 'marzha_unit', width: 70, className: 'green-color', render: (text) => formatPercent(text),},
            ],
        },

        // Условие для скрытия столбцов
        {
            title: 'План',
            className: 'yellow-color',
            hidden: hiddenColumns.includes('План'), // Проверка на скрытие
            children: [
                { title: 'Шт', dataIndex: 'plan_orders', width: 50, className: 'yellow-color', render: (text) => formatQuantity(text) },
                { title: 'Шт в день', dataIndex: 'plan_orders_day', width: 50, className: 'yellow-color', render: (text) => formatQuantity(text) },
            ],
        },
        {
            title: 'Факт',
            className: 'blue-color',
            hidden: hiddenColumns.includes('Факт'), // Проверка на скрытие
            children: [
                { title: 'Продажи в день', dataIndex: 'orders_per_day', width: 70, className: 'blue-color', render: (text) => formatQuantity(text) },
                { title: 'Выкупы в день', dataIndex: 'buyouts_per_day', width: 70, className: 'blue-color', render: (text) => formatQuantity(text) },
            ],
        },
        {
            title: 'Склады',
            className: 'pink-color',
            hidden: hiddenColumns.includes('Склады'), // Проверка на скрытие
            children: [
                { title: 'Москва', dataIndex: 'ms_ost_msk', width: 70, className: 'pink-color', render: (text) => formatQuantity(text) },
                { title: 'Бишкек', dataIndex: 'ms_ost_bishkek', width: 70, className: 'pink-color', render: (text) => formatQuantity(text) },
            ],
        },
        {
            title: 'Производство',
            className: 'green-color',
            hidden: hiddenColumns.includes('Производство'), // Проверка на скрытие
            children: [
                { title: 'Шьется', dataIndex: 'ms_production', width: 70, className: 'green-color', render: (text) => formatQuantity(text) },
            ],
        },
        {
            title: 'Транзит',
            className: 'green-color',
            hidden: hiddenColumns.includes('Транзит'), // Проверка на скрытие
            children: [
                { title: 'В пути шт', dataIndex: 'ms_transfer', width: 70, className: 'green-color', render: (text) => formatQuantity(text) },
            ],
        },
        {
            title: 'ЗАПАС В ДНЯХ ВСЕ СКЛАДЫ',
            className: 'pink-color',
            hidden: hiddenColumns.includes('Запас'), // Проверка на скрытие
            children: [
                {title: 'Остатки на складах', dataIndex: 'ost_wb', width: 70, className: 'pink-color', render: (text) => formatQuantity(text),},
                {title: 'В пути до клиента', dataIndex: 'ost_wb_way_to', width: 70, className: 'pink-color', render: (text) => formatQuantity(text),},
                {title: 'В пути от клиента', dataIndex: 'ost_wb_way_from', width: 70, className: 'pink-color', render: (text) => formatQuantity(text),},
                {title: 'Транзит', dataIndex: 'ms_transfer', width: 70, className: 'pink-color', render: (text) => formatQuantity(text),},
                {title: 'Остатки итого', dataIndex: 'ost_wb_full', width: 70, className: 'pink-color', render: (text) => formatQuantity(text),},
                {title: 'Продажи 14 дней средние', dataIndex: 'orders_per_day', width: 70, className: 'pink-color',},
                {title: 'Оборачиваемость', dataIndex: 'oborot_orders', width: 70, className: 'pink-color', render: (text) => formatDays(text),},
                {title: 'Запас дней', dataIndex: 'oborot_buyouts', width: 70, className: 'pink-color', render: (text) => formatDays(text),},
                {title: 'Потребность БЕЗ УЧЕТА ТРАНЗИТА', dataIndex: 'need', width: 70, className: 'pink-color', render: (text) => formatQuantity(text),},
            ],
        },
    ];
};

const getStoreColumns = (storeNames, showOnlyNeed, toggleStoreVisibility, hiddenColumns) => {
    let columns = [];
    storeNames.forEach((name) => {
        let item = {
            title: (
                <div style={{'display': 'flex', 'align-items': 'center', gap:'5px'}}>
                    <Button size='small' onClick={() => toggleStoreVisibility(name)}>
                        {hiddenColumns.includes(name) ? <MinusOutlined/> : <PlusOutlined/>}
                    </Button>
                    {name}
                </div>
            ),
            className: 'border_left',
            children:  hiddenColumns.includes(name)
            
                ? [
                    {
                        title: 'Запас в днях',
                        dataIndex: `${name}oborot_buyouts`,
                        className: 'border_left',
                        width: 70,
                        render: (text) => formatDays(text),
                    },
                    {
                        title: 'Оборачиваемость',
                        dataIndex: `${name}oborot`,
                        width: 70,
                        render: (text) => formatDays(text),
                    },
                    {
                        title: 'Остатки',
                        dataIndex: `${name}ost`,
                        width: 70,
                        render: (text) => formatQuantity(text),
                    },
                    {
                        title: 'В пути от клиента',
                        dataIndex: `${name}way_from`,
                        width: 70,
                        render: (text) => formatQuantity(text),
                    },
                    {
                        title: 'Продажи - 14 дней',
                        dataIndex: `${name}orders_per_day`,
                        width: 70,
                        render: (text) => formatQuantity(text),
                    },
                    {
                        title: 'Потребность',
                        dataIndex: `${name}need`,
                        className: 'blue-color',
                        width: 70,
                        render: (text) => {
                            return text >= 30 ? (
                                <div style={{ backgroundColor: '#ff6969' }}>
                                    {formatQuantity(text)}
                                </div>
                            ) : formatQuantity(text);
                        },
                    },
                ]
                : [
                    {
                        title: 'Потребность',
                        dataIndex: `${name}need`,
                        className: 'blue-color',
                        width: 100,
                        render: (text) => {
                            return text >= 30 ? (
                                <div style={{ backgroundColor: '#ff6969' }}>
                                    {formatQuantity(text)}
                                </div>
                            ) : formatQuantity(text);
                        },
                    },
                ]
        };
        columns.push(item);
    });
    return columns;
};


const expandData = (cards) => {
    return cards.map((card) => {
        for (let storeName in card.by_stores) {
            card[`${storeName}oborot_buyouts`] = card.by_stores[storeName].oborot_buyouts;
            card[`${storeName}oborot_orders`] = card.by_stores[storeName].oborot_orders;
            card[`${storeName}ost`] = card.by_stores[storeName].ost;
            card[`${storeName}way_from`] = card.by_stores[storeName].way_from;
            card[`${storeName}wb_transfer`] = card.by_stores[storeName].transfer;
            card[`${storeName}orders_per_day`] = card.by_stores[storeName].orders_per_day;
            card[`${storeName}need`] = card.by_stores[storeName].need;
        }
        for (let storeName in card.by_regions) {
            card[`${storeName}oborot_buyouts`] = card.by_regions[storeName].oborot_buyouts;
            card[`${storeName}oborot_orders`] = card.by_regions[storeName].oborot_orders;
            card[`${storeName}ost`] = card.by_regions[storeName].ost;
            card[`${storeName}way_from`] = card.by_regions[storeName].way_from;
            card[`${storeName}wb_transfer`] = card.by_regions[storeName].transfer;
            card[`${storeName}orders_per_day`] = card.by_regions[storeName].orders_per_day;
            card[`${storeName}need`] = card.by_regions[storeName].need;
        }
        card['children'] = [];
        for (let barcode in card.by_barcode) {
            let item = card.by_barcode[barcode];
            item.subject_name = card.subject_name;
            item.subject_id = card.subject_id;
            item.material = card.material;
            item.color = card.color;
            item.abc = card.abc;
            item.sezon = card.sezon;
            item.status = card.status;


            for (let storeName in item.by_stores) {
                item[`${storeName}oborot_buyouts`] = item.by_stores[storeName].oborot_buyouts;
                item[`${storeName}oborot_orders`] = item.by_stores[storeName].oborot_orders;
                item[`${storeName}ost`] = item.by_stores[storeName].ost;
                item[`${storeName}way_from`] = item.by_stores[storeName].way_from;
                item[`${storeName}wb_transfer`] = item.by_stores[storeName].transfer;
                item[`${storeName}orders_per_day`] = item.by_stores[storeName].orders_per_day;
                item[`${storeName}need`] = item.by_stores[storeName].need;
            }
            for (let storeName in item.by_regions) {
                item[`${storeName}oborot_buyouts`] = item.by_regions[storeName].oborot_buyouts;
                item[`${storeName}oborot_orders`] = item.by_regions[storeName].oborot_orders;
                item[`${storeName}ost`] = item.by_regions[storeName].ost;
                item[`${storeName}way_from`] = item.by_regions[storeName].way_from;
                item[`${storeName}wb_transfer`] = item.by_regions[storeName].transfer;
                item[`${storeName}orders_per_day`] = item.by_regions[storeName].orders_per_day;
                item[`${storeName}need`] = item.by_regions[storeName].need;
            }
            card['children'].push(item);
        }
        return card;
    });
};

export default function TableSupplies(args) {
  const [isHideColumns, setHideColumns] = useState(false);
    const [hiddenColumns, setHiddenColumns] = useState([]);
    const [showOnlyNeed, setShowOnlyNeed] = useState(true);

    const toggleStoreVisibility = (storeName) => {
        setHiddenColumns((prevHidden) =>
            prevHidden.includes(storeName)
                ? prevHidden.filter((name) => name !== storeName)
                : [...prevHidden, storeName]
        );
    };

    let columns = getColumns(args.filters, args.likeHideList, args.onChangeLikeHide, isHideColumns, hiddenColumns,args.isOzon);

    if (args.storeNames) {
        let newColumns = getStoreColumns(args.regionNames, showOnlyNeed, toggleStoreVisibility, hiddenColumns);
        columns = [...columns, ...newColumns];
        newColumns = getStoreColumns(args.storeNames, showOnlyNeed, toggleStoreVisibility, hiddenColumns);
        columns = [...columns, ...newColumns];
    }

    let DataSource = args.cards;
    if (args.cards) {
        DataSource = expandData(args.cards);
    }


    const toggleColumnVisibility = (columnName) => {
        setHiddenColumns((prev) => 
            prev.includes(columnName) 
            ? prev.filter((name) => name !== columnName) 
            : [...prev, columnName]
        );
    };

    
    return (
        <>
            <Flex gap={5}>
                <Switch
                checkedChildren='Скрыть'
                unCheckedChildren='Скрыть'
                checked={isHideColumns}
                onChange={() => setHideColumns(!isHideColumns)}
                />
                {['План', 'Факт', 'Склады', 'Производство', 'Транзит', 'Запас'].map(columnName => (
                    <Switch
                        key={columnName}
                        checkedChildren={columnName}
                        unCheckedChildren={columnName}
                        checked={!hiddenColumns.includes(columnName)}
                        onChange={() => toggleColumnVisibility(columnName)}
                    />
                ))}
            </Flex>
            <Table
                columns={columns}
                dataSource={DataSource}
                bordered
                size={'small'}
                scroll={{
                    y: 800,
                }}
                rowClassName={(record, index) =>
                    `my-custom-row ${record.article ? 'border_top' : ''}`
                }
                loading={args.loading}
                pagination={{
                    defaultPageSize: 30,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} из ${total} артикулов`,
                }}
            />
        </>
    );
}

