import React from 'react';
import {Row, Col, Input, Button, DatePicker, Flex, Typography, Select} from 'antd';
import {CalendarOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';

const {RangePicker} = DatePicker;


const disabled30DaysDate = (current, {from, type}) => {
    if (from) {
        return Math.abs(current.diff(from, 'days')) >= 31;
    }
    return false;
};

const rangePresets = [
    {
        label: 'Последние 7 дней',
        value: [dayjs().add(-7, 'd'), dayjs()],
    },
    {
        label: 'Последние 14 дней',
        value: [dayjs().add(-14, 'd'), dayjs()],
    },
    {
        label: 'Последние 30 дней',
        value: [dayjs().add(-30, 'd'), dayjs()],
    },
    {
        label: 'Текущий месяц',
        value: [dayjs().startOf('month'), dayjs()],
    },
];


const DatePickerRange = (props) => {
    const { selected, setSelected } = props;
    return (

        <RangePicker
            suffixIcon={<CalendarOutlined/>}
            presets={rangePresets}
            defaultValue={selected}
            minDate={dayjs('2024-06-01', 'YYYY-MM-DD')}
            maxDate={dayjs()}
            disabledDate={disabled30DaysDate}
            onChange={(value) => setSelected(value)}
        />

    );
};
export default DatePickerRange;