import React, {useEffect, useState} from 'react';
import {Button, Card, Typography, Tag, Flex, Divider, Space, Col, Row, Modal, Input, InputNumber, Select} from 'antd';
import {formatCurrency} from "../../../core/utils/formats";
import {getAdvertBalance, getAdvertBalanceWBAcc, advertAddBalance, advertSetCpm, advertSetActive} from "../../../core/api/marketing/adverts";
import {SelectorSolo} from "../selects";
import DatePickerRange from "../dateRange";

const {Title, Paragraph, Text} = Typography;

export const AdvertCard = (data) => {
    const advert = data.advert ? data.advert : {}

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [addBalance, setAddBalance] = useState(1000)
    const [selectedBalanceMethod, setSelectedBalanceMethod] = useState(1)
    const [balance, setBalance] = useState(0)
    const [cpm, setCpm] = useState(100)
    const [balanceWBAcc, setBalanceWBAcc] = useState({balance: 0, net: 0})
    const [advertStatus, setAdvertStatus] = useState(advert.status)
    const [isHide, setIsHide] = useState(true)

    const balanceOptions = [{value: 1, label: `Баланс: ${formatCurrency(balanceWBAcc.net)}`}, {value: 0, label: `Счет: ${formatCurrency(balanceWBAcc.balance)}`}]
    useEffect(() => {
        setAdvertStatus(advert.status)
        setIsHide([1, 7, 8].includes(advert.status));
        if (advert.id) {
            getAdvertBalance(advert.id, advert.wbacc_id, setBalance);
            getAdvertBalanceWBAcc(advert.wbacc_id, setBalanceWBAcc)
        }
        if (advert.advert_type == 8) {
            setCpm(advert.params.cpm);
        }
        if (advert.advert_type == 9) {
            console.log(advert.params[0].searchCPM)
            setCpm(advert.params[0].searchCPM);
        }
    }, [advert.status]);

    const handleOk = () => {
        setIsModalOpen(false);
        console.log('addBalance', addBalance);
        advertAddBalance(advert.wbacc_id, advert.id, addBalance, setBalance, selectedBalanceMethod, data.isCsrf);
        setAddBalance(1000);
    };

    const handleConfirmCpm = (e) => {
        console.log('handleConfirmCpm', e.target.value);
        const param = advert.advert_type == 8 ? advert.params.subject.id : advert.params[0].subject.id
        advertSetCpm(advert.wbacc_id, advert.id, advert.advert_type, e.target.value, param, 6, data.isCsrf);
    }
    const handleStart = () => {
        if (advertStatus == 11) {
            advertSetActive(advert.wbacc_id, advert.id, 'start', setAdvertStatus, data.isCsrf)
        } else {
            advertSetActive(advert.wbacc_id, advert.id, 'pause', setAdvertStatus, data.isCsrf)
        }
    }
    const handleStop = () => {
        advertSetActive(advert.wbacc_id, advert.id, 'stop', setAdvertStatus, data.isCsrf)
    }

    const statusColor = {
        1: 'red',
        4: 'green',
        7: 'black',
        8: 'black',
        9: 'green',
        11: 'blue',
    }
    const statusName = {
        1: 'В процессе удаления',
        4: 'Готова к запуску',
        7: 'Завершена',
        8: 'Отказался',
        9: 'Активна',
        11: 'Пауза',
    }
    return (
        <Card>
            <Flex gap="middle">
                <span>{advert.type_name}</span>
                <span>ID {advert.id}</span>
                <span>Создана {advert.create_time}</span>
                <Tag color={statusColor[advertStatus]}>{statusName[advertStatus]}</Tag>
            </Flex>
            <Title level={4} style={{marginTop: '0'}}>{advert.name} </Title>
            {(!isHide) && (
                <Flex gap="middle">
                    <Button type="default" onClick={handleStart}>{(advertStatus == 11) ? 'Запустить' : 'Приостановить'} </Button>
                    <Button danger type="default" onClick={handleStop}>Завершить</Button>
                </Flex>
            )}
            <Flex style={{marginTop: '10px'}}>
                <div>
                    <Title level={5} style={{marginTop: '0'}}>Категория товаров</Title>
                    <div style={{marginTop: '12px'}}>{advert.subject_name}</div>
                </div>
                <hr style={{marginRight: '15px', marginLeft: '15px'}}/>
                <div>
                    <Title level={5} style={{marginTop: '0'}}>Товаров в кампании</Title>
                    <div style={{marginTop: '12px'}}>{advert.quantity_nm_ids}</div>
                </div>
                {(!isHide) && (<>
                    <hr style={{marginRight: '15px', marginLeft: '15px'}}/>
                    <div>
                        <Title level={5} style={{marginTop: '0'}}>Бюджет</Title>
                        {(!isHide) ? (
                            <div style={{marginTop: '12px'}}><a onClick={() => setIsModalOpen(true)} style={{padding: '0'}}>{formatCurrency(balance)}</a></div>
                        ) : (
                            <div style={{marginTop: '12px'}}>{formatCurrency(0)}</div>
                        )}
                    </div>
                    <hr style={{marginRight: '15px', marginLeft: '15px'}}/>
                    <div>
                        <Title level={5} style={{marginTop: '0'}}>Ставка CPM, ₽</Title>
                        <InputNumber min={100} value={cpm} onChange={(value) => setCpm(value)} onBlur={handleConfirmCpm} onPressEnter={handleConfirmCpm}/>
                    </div>
                </>)}

            </Flex>
            <Typography.Title level={5}>
                Период данных статистики
            </Typography.Title>
            <DatePickerRange
                selected={data.period}
                setSelected={data.setPeriod}
            />
            <Modal title="Пополнение бюджета кампании" open={isModalOpen}
                   centered
                   onCancel={() => setIsModalOpen(false)}
                   footer={[
                       <Button key="submit" type="primary" onClick={handleOk}>
                           Пополнить
                       </Button>,
                       <Button key="back" onClick={() => setIsModalOpen(false)}>
                           Отменить
                       </Button>,
                   ]}>
                <span>Сумма пополнения (₽)</span>
                <Input placeholder="Сумма пополнения" value={addBalance} onChange={(e) => setAddBalance(e.target.value)}/>
                <SelectorSolo options={balanceOptions} selected={selectedBalanceMethod} setSelected={setSelectedBalanceMethod}/>
            </Modal>


        </Card>
    )
        ;
};
