import axios from 'axios';
import { serverUrl } from "../../constants";


export const getDetails = async(wbacc_ids, is_like, is_hide, period) => {
    try {
        const response = await axios.get(serverUrl + "marketing-detail/get/", {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                'wbacc_ids': [wbacc_ids],
                'is_like': is_like,
                'is_hide': is_hide,
                'st': period[0].format('YYYY-MM-DD'),
                'en': period[1].format('YYYY-MM-DD'),
            }
        });
        console.log('Response data:', response.data);
        return response.data;
    } catch (error) {
        if (error.response) {
            // Ошибка от сервера
            console.error('Server responded with error:', error.response.data);
        } else if (error.request) {
            // Запрос был отправлен, но ответа не было
            console.error('No response received:', error.request);
        } else {
            // Произошла ошибка при настройке запроса
            console.error('Error setting up request:', error.message);
        }
        return [];
    }
};